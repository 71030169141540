import { useMutation, useQueryClient } from 'react-query';
import { PaymentService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useNavigate } from 'react-router-dom';
import { useLocaleContext } from '../context/LocaleContext';

export const useUpgradePlan = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { createSnackBar } = useSnackBarContext();
  const { getLocaleLink } = useLocaleContext();
  const upgradePlanMutation = useMutation(
    PaymentService.upgradeSubscriptionApiPaymentUpgradeSubscriptionPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currentPlan');
        createSnackBar({
          content: 'Successfully upgraded plan',
          severity: 'success',
          autoHide: true
        });
        navigate(getLocaleLink('/organization/details'));
      },
      onError: () => {
        queryClient.invalidateQueries('currentPlan');
        createSnackBar({
          content: 'Failed to upgrade plan',
          severity: 'error',
          autoHide: true
        });
      }
    }
  );
  return upgradePlanMutation;
};
