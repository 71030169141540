import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useGame from '../@core/useGame';
import useSceneManager from '../@core/useSceneManager';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import { Building } from '../@core/utils/movementHelpers';
import { PubSubEvent } from '../@core/utils/createPubSub';
import { useCommandError } from '../hooks/useCommandError';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { XMARK_LIST } from '../@core/utils/xmarkList';

interface Structure {
  name: string;
  color?: string;
  size?: string;
}

function isStructure(value: any): value is Structure {
  return typeof value === 'object' && 'name' in value && typeof value.name === 'string';
}

export interface TypedPosition extends Position {
  positionType: 'horizontal' | 'vertical';
  size?: 'small' | 'big';
  color?: 'green' | 'red' | 'orange';
}

export type BuildEvent = PubSubEvent<'build', TypedPosition>;

const buildingData = ['bridge', 'Grain', 'wood', 'stone', 'chicken_house'];

function BuildingScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName } = useGame();
  const { levelData, setLevelData, inventory, setInventoryData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();
  const playBuildWoodSound = useSound(soundData.buildWood);
  const playBuildStoneSound = useSound(soundData.buildStone);
  const playBuildHayStackSound = useSound(soundData.hayStack);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const buildStep = step as Building;
    const structure = buildStep.structure;

    if (!isStructure(structure)) {
      return;
    }

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const buildingPosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const buildingObjectGoal = levelGoals.find(
      (goal) =>
        buildingData.find((data) => goal.data?.startsWith(data)) &&
        XMARK_LIST.includes(goal?.type || '') &&
        goal.action === 'build' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    if (!buildingObjectGoal) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjectsPosition = {
      x: buildingObjectGoal.targetPosition?.[0],
      y: buildingObjectGoal.targetPosition?.[1]
    };

    if (!targetObjectsPosition.x || !targetObjectsPosition.y) {
      triggerError('Unexpected game error');
      return;
    }

    if (
      targetObjectsPosition.x !== buildingPosition.x ||
      targetObjectsPosition.y !== buildingPosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.build.position);
      return;
    }

    const inventoryItem = inventory.find((item) => item.type === buildingObjectGoal?.cost?.type);

    const buildCost = buildingObjectGoal?.cost?.amount || 0;

    const inventoryCount = inventoryItem?.count || 0;

    const { name: structureName, color: structureColor, size: structureSize } = structure;

    if (buildCost !== 0) {
      if (!inventoryItem || inventoryCount < buildCost) {
        const buildCostType = buildingObjectGoal?.cost?.type || 'bridge';
        triggerError(UNEXPECTED_ERRORS.build[buildCostType]);
        return;
      }
    }

    if (!buildingObjectGoal.data?.startsWith(structureName)) {
      triggerError(UNEXPECTED_ERRORS.build.data(buildingObjectGoal?.data));
      return false;
    }

    const buildHorizontal = dirX !== 0;

    const buildPosition = {
      x: obj.transform.x + dirX,
      y: obj.transform.y + dirY,
      positionType: buildHorizontal ? 'horizontal' : 'vertical',
      size: structureSize,
      color: structureColor
    };

    const buildState = buildHorizontal ? 'bridgeH' : 'bridgeV';
    const bridgeObject = findGameObjectByName(
      `${buildState}-${buildPosition.x}-${buildPosition.y}`
    );
    bridgeObject?.setDisabled(false);

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item?.type || '') &&
        item.action === 'build' &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    setInventoryData(
      inventory.map((item) => {
        if (!inventoryItem || (item.type === inventoryItem.type && item.count > 0)) {
          return { ...item, count: (item.count -= buildCost) };
        }
        return item;
      })
    );

    const handleBuild = async (
      soundFunction: () => void,
      initialDelay: number,
      finalDelay: number
    ) => {
      soundFunction();
      await waitForMs(initialDelay);
      publish<BuildEvent>('build', buildPosition as TypedPosition);
      publish<DidCollectEvent>('did-collect', newLevelData);
      xmarkState.current = !xmarkState.current;
      if (xmarkState.current) {
        getRef().setDisabled(true);
      }
      return await waitForMs(finalDelay);
    };

    switch (structureName) {
      case 'bridge':
      case 'wood':
      case 'chicken_house':
        return await handleBuild(playBuildWoodSound, 500, 0);
      case 'Grain':
        return await handleBuild(playBuildHayStackSound, 500, 1500);
      case 'stone':
        return await handleBuild(playBuildStoneSound, 500, 0);
      default:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return await handleBuild(() => {}, 50, 0);
    }
  });

  return null;
}

interface BuildProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemBuild({ props, state, spriteData }: BuildProps) {
  const name = `${state}-${props.x}-${props.y}`;

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="build" />
      <BuildingScript />
    </GameObject>
  );
}
