import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { initLocale } from './i18n';
import { languages } from './components/LocaleSelect/languages';

const supportedLocales = languages.map((lang) => lang.code);

// If locale is not supported, set it to English
if (!supportedLocales.includes(localStorage.getItem("locale") || "")) {
  localStorage.setItem("locale", "en");
}

initLocale(localStorage.getItem("locale") || "en"); // makes sure the locale is set on the first load

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

Sentry.init({
  dsn: 'https://5d2bf84dcf2cb5b1e18d22266522df20@o4507199770591232.ingest.us.sentry.io/4507199774130176',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/codingforkids\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !development
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

const theme = createTheme({
  transitions: {
    duration: {
      shortest: 0,
      shorter: 0,
      short: 0,
      standard: 0,
      complex: 0,
      enteringScreen: 0,
      leavingScreen: 0
    }
  },
  typography: {
    fontFamily: 'Urbanist, sans-serif',
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
    fontWeightBold: 800
  },
  palette: {
    mode: 'dark',
    primary: {
      dark: '#65b700',
      main: '#75d500',
      light: '#82fb00'
    },
    secondary: {
      dark: '#3321BC',
      main: '#3a25d9',
      light: '#442bff'
    },
    background: {
      default: '#303129',
      paper: '#272822'
    }
  },
  zIndex: { appBar: 1200, drawer: 1100 },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backdropFilter: 'blur(10px)'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Silkscreen',
          fontWeight: 800
        },
        h2: {
          fontFamily: 'Silkscreen',
          fontWeight: 800
        },
        h3: {
          fontFamily: 'Silkscreen',
          fontWeight: 800
        },
        h4: {
          fontFamily: 'Silkscreen',
          fontWeight: 500
        },
        h5: {
          fontFamily: 'Silkscreen',
          fontWeight: 500
        },
        h6: {
          fontFamily: 'Silkscreen',
          fontWeight: 500
        },
        body1: {
          fontSize: 18
        }
      }
    },
    MuiChip: {
      defaultProps: {
        style: {
          borderRadius: 2
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        style: {
          fontFamily: 'Silkscreen',
          textTransform: 'uppercase',
          fontWeight: 'normal',
          transition: 'none'
        }
      }
    }
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ConfirmProvider>
              <App />
            </ConfirmProvider>
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    </LocalizationProvider>
  </QueryClientProvider>
);
