import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { languages } from './languages';
import { useCurrentLocale } from '../../hooks/useCurrentLocale';
import { useLocaleChange } from '../../hooks/useLocaleChange';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

export const LocaleSelect = () => {
  const { data: locale, isLoading } = useCurrentLocale();
  const { lng } = useParams();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const { mutate } = useLocaleChange();
  const { i18n } = useTranslation();

  const selectedLanguage = languages.find((lang) => lang.name === locale?.language) || languages[0];

  const handleChange = (event: SelectChangeEvent) => {
    const newLanguage = languages.find((lang) => lang.name === event.target.value) || languages[0];
    mutate(event.target.value as string, {
      onSuccess: () => {
        localStorage.setItem('locale', newLanguage.code);
        const newPathname = pathname.replace(`/${lng}`, `/${newLanguage.code}`);
        window.location.href = `${newPathname}`;
        queryClient.invalidateQueries('currentLocale');
      }
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box component="div" sx={{ position: 'relative', display: 'inline-block' }}>
      <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
        <Select
          labelId="locale-select-label"
          id="locale-select"
          value={locale?.language}
          onChange={handleChange}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 220,
                width: 'auto'
              }
            }
          }}
          sx={{
            position: 'absolute',
            width: 40,
            height: 40,
            opacity: 0,
            zIndex: 1
          }}
        >
          {languages.map((language) => (
            <MenuItem
              key={language.name}
              value={language.name}
              sx={{
                minHeight: 'auto'
              }}
            >
              {language.flag_emoji} {language.native_name}
            </MenuItem>
          ))}
        </Select>

        <IconButton
          sx={{
            width: 40,
            height: 40,
            fontSize: '24px',
            borderRadius: '50%',
            backgroundColor: 'transparent'
          }}
        >
          {selectedLanguage?.flag_emoji}
        </IconButton>
      </FormControl>
    </Box>
  );
};
