import { CheckoutSettings, initializePaddle, Paddle } from '@paddle/paddle-js';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../pages/App';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useLocaleContext } from '../context/LocaleContext';

// sandbox
// const PADDLE_CLIENT_SIDE_TOKEN = 'test_af77d3e6ecd6f3c90fb1c967ced';
// const PADDLE_ENV = 'sandbox';
// export const PADDLE_SINGLE_PRICE_ID = 'pri_01gsz8ntc6z7npqqp6j4ys0w1w';
// export const PADDLE_SUBSCRIPTION_PRICE_ID = 'pri_01hvk9mt8604tm5xxhve4sk10a';
// export const PADDLE_SMALL_ORG_PRICE_ID = 'pri_01j0g945m7bf8pfmkd7kqe80aa';
// export const PADDLE_LARGE_ORG_PRICE_ID = 'pri_01j0g94txx6bmv92djfma5htxb';

const PADDLE_CLIENT_SIDE_TOKEN = 'live_e07a7bc653517d55059a07d6ec4';
const PADDLE_ENV = 'production';
export const PADDLE_SINGLE_PRICE_ID = 'pri_01hsft62ta91g9geypp9ygtwn1';
export const PADDLE_SUBSCRIPTION_PRICE_ID = 'pri_01hvbvrnd5ea14p750p66efgw2';
export const PADDLE_SMALL_ORG_PRICE_ID = 'pri_01hxgf887e6s6dmpcg37knedke';
export const PADDLE_LARGE_ORG_PRICE_ID = 'pri_01hxgf8j1fxjsd6xkg1swtcnxz';

// const PADDLE_CLIENT_SIDE_TOKEN = 'test_7d279f61a3499fed520f7cd8c08';
// const PADDLE_PRICE_ID = 'pri_01gsz8ntc6z7npqqp6j4ys0w1w';
// const PADDLE_ENV = 'sandbox';

// const PADDLE_CLIENT_SIDE_TOKEN = process.env.PADDLE_CLIENT_SIDE_TOKEN;
// const PADDLE_PRICE_ID = process.env.PADDLE_PRICE_ID;
// const PADDLE_ENV = process.env.PADDLE_ENV;

const SETTINGS: CheckoutSettings = {
  displayMode: 'overlay',
  theme: 'dark',
  locale: 'en',
  showAddDiscounts: true
};

export default function useCheckout() {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState<Paddle>();

  const { getLocaleLink } = useLocaleContext();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useContext(UserContext);
  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({
      environment: PADDLE_ENV,
      token: PADDLE_CLIENT_SIDE_TOKEN,
      eventCallback: function (data) {
        if (data.name == 'checkout.completed') {
          console.log(data);
          queryClient.invalidateQueries('hasUnassignedPurchase');
        }
      }
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  // Callback to open a checkout
  const openCheckout = (priceId: string) => {
    if (!paddle) {
      alert('Paddle is not initialized yet');
      return;
    }
    if (!currentUser) {
      navigate(getLocaleLink('/signup'));
      return;
    }

    paddle.Checkout.open({
      customer: {
        email: currentUser.email
      },
      items: [{ priceId: priceId, quantity: 1 }],
      settings: SETTINGS
    });
  };

  const updateCheckout = (txnId: string) => {
    if (!paddle) {
      alert('Paddle is not initialized yet');
      return;
    }
    if (!currentUser) {
      navigate(getLocaleLink('/signup'));
      return;
    }

    paddle.Checkout.open({
      customer: {
        email: currentUser.email
      },
      transactionId: txnId,
      settings: SETTINGS
    });
  };

  return { openCheckout, updateCheckout };
}
