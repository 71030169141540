import { Box, Button, CircularProgress, styled, Typography } from '@mui/material';
import { useAppContext } from '../context/AppContext';
import { useContext } from 'react';
import { UserContext } from '../pages/App';
import { useCurrentPlan } from '../hooks/useCurrentPlan';
import { useLocaleContext } from '../context/LocaleContext';

const BoxOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000003f;
  backdrop-filter: blur(3.5px);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export default function ChooseStudentOverlay() {
  const { setOpenChooseStudent } = useAppContext();
  const currentUser = useContext(UserContext);
  const { data: currentPlan, isLoading } = useCurrentPlan();

  const { getLocaleLink } = useLocaleContext();

  if (isLoading)
    return (
      <BoxOverlay>
        <CircularProgress />
      </BoxOverlay>
    );

  if (currentPlan?.subscription === 'parent' && currentUser?.organization_id) {
    return (
      <BoxOverlay>
        <Typography
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            padding: '16px',
            borderRadius: '8px',
            fontSize: '1.25rem'
          }}
        >
          Invalid product registration detected. A parent tier was used for an organization tier,
          which is not allowed. The parent tier is not intended for schools. Please upgrade to the
          appropriate school tier instead.
        </Typography>
        <Button variant="contained" href={getLocaleLink("/schools")}>
          Upgrade
        </Button>
      </BoxOverlay>
    );
  }

  return currentUser && !currentUser?.student_id ? (
    <BoxOverlay>
      <Typography
        variant="h4"
        maxWidth="sm"
        sx={{
          textAlign: 'center',
          padding: '16px',
          borderRadius: '8px'
        }}
      >
        Please choose a student to play
      </Typography>
      <Button variant="contained" onClick={() => setOpenChooseStudent(true)}>
        Choose Student
      </Button>
    </BoxOverlay>
  ) : null;
}
