import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { OrganizationService, CreateOrganizationTeacherForm } from '../../../client';
import { useSnackBarContext } from '../../../context/SnackBarContext';
import { AxiosError } from 'axios';
import { Loader } from '../../Loader';
import { useAddTeacherForm } from '../../../hooks/useAddTeacherForm';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocaleContext } from '../../../context/LocaleContext';

export const AddEditTeacherUserForm = () => {
  const { teacherId } = useParams();
  const { createSnackBar } = useSnackBarContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const { getLocaleLink } = useLocaleContext();

  const { data: teacher, isLoading: isTeacherLoading } = useQuery(
    ['teacher-user', teacherId],
    () =>
      OrganizationService.getOrganizationTeacherApiOrganizationTeachersTeacherIdGet(
        teacherId || ''
      ),
    {
      enabled: !!teacherId,
      retry: false
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useAddTeacherForm();

  const addTeacherMutation = useMutation(
    OrganizationService.createTeacherApiOrganizationTeacherPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teachers');
        navigate(getLocaleLink('/organization/teachers'));
        createSnackBar({
          content: 'New Teacher created successfully.',
          autoHide: true,
          severity: 'success'
        });
      },
      onError: (error: AxiosError) => {
        if (error.status === 409) {
          createSnackBar({
            content: 'Email already exists.',
            autoHide: true,
            severity: 'error'
          });
        } else {
          createSnackBar({
            content: 'Adding teacher failed',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  const editTeacherMutation = useMutation(
    (updatedTeacherUser: CreateOrganizationTeacherForm) =>
      OrganizationService.updateTeacherApiOrganizationTeacherTeacherIdPut(
        teacherId || '',
        updatedTeacherUser
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teachers');
        createSnackBar({
          content: 'Teacher details updated successfully.',
          autoHide: true,
          severity: 'success'
        });
        navigate(getLocaleLink('/organization/teachers'));
      },
      onError: () => {
        createSnackBar({ content: 'Editing teacher failed', autoHide: true, severity: 'error' });
      }
    }
  );

  const onSubmit = async (data: CreateOrganizationTeacherForm) => {
    try {
      if (!teacherId) {
        await addTeacherMutation.mutateAsync(data);
        return;
      }
      await editTeacherMutation.mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  if (teacherId && isTeacherLoading) return <Loader />;
  if (teacherId && !teacher) return <Box component="div">Teacher user not found</Box>;

  return (
    <Paper sx={{ p: 5, mt: 4 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="div" display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>
              <Stack gap={4} flex={1}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={teacher?.email}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      fullWidth
                      label="Email"
                      margin="none"
                      required
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="full_name"
                  control={control}
                  defaultValue={teacher?.full_name}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={!!errors.full_name}
                      helperText={errors.full_name?.message}
                      fullWidth
                      label="Full Name"
                      margin="none"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      fullWidth
                      label={!teacherId ? 'Password' : 'New password (optional)'}
                      margin="none"
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      label="Confirm new password"
                      fullWidth
                      margin="none"
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" display="flex" justifyContent="flex-end" mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !!errors.confirmPassword}
              >
                {isSubmitting || addTeacherMutation.isLoading || editTeacherMutation.isLoading
                  ? 'Saving...'
                  : 'Save'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
