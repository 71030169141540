'use client';

import { FiberNew, Menu, MenuOpen } from '@mui/icons-material';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { useSidebar } from '../context/SidebarContext';
import LogoWithText from './LogoWithText';
import AccountMenu from './AccountMenu';
import { useLocaleContext } from '../context/LocaleContext';

export const NAVBAR_PAGES = [['Challenges', '/challenges']];

const NAVBAR_BADGES: {
  [key: string]: { new: boolean };
} = {
  Challenges: { new: true }
};

export default function Header() {
  const { handleDrawerOpen, handleDrawerClose, openSidebar } = useSidebar();
  const { getLocaleLink } = useLocaleContext();
  return (
    <AppBar component="header" position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={openSidebar ? handleDrawerClose : handleDrawerOpen}
          edge="start"
        >
          {openSidebar ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Box
          component="div"
          sx={{
            flexGrow: { xs: 1, md: 0 },
            width: { xs: '100%', md: 'auto' },
            justifyContent: { xs: 'center', md: 'start' },
            display: 'flex'
          }}
        >
          <LogoWithText />
        </Box>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            alignItems: 'start',
            justifyContent: { xs: 'center', md: 'start' },
            gap: 1.5,
            mx: 2
          }}
        >
          {NAVBAR_PAGES.map(([page, location]) => (
            <Button
              key={page}
              href={getLocaleLink(location)}
              sx={{ color: 'white', position: 'relative' }}
            >
              {page}
              {NAVBAR_BADGES[page]?.new && (
                <FiberNew
                  sx={{
                    position: 'absolute',
                    top: -0.5,
                    right: -13,
                    fontSize: '1.4rem'
                  }}
                  color="primary"
                />
              )}
            </Button>
          ))}
        </Box>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
}
