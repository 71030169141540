import { Box, Button, CircularProgress, Divider, Tab, Tabs, Typography } from '@mui/material';
import TipModal from '../components/TipModal';
import ErrorModal from '../components/ErrorModal';
import ChooseStudentModal from '../components/ChooseStudentModal';
import AceEditor from 'react-ace';
import React, { useEffect, useState } from 'react';
import { Ace } from 'ace-builds';
import { useCode } from '../context/CodeContext';
import { useOutput } from '../context/OutputContext';
import MarkdownSection from '../components/MarkdownSection';
import { PlayArrow, QuestionMark, Refresh } from '@mui/icons-material';
import usePyodide from '../hooks/usePyodide';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useParams } from 'react-router-dom';
import { ChallengeCompletionStatus } from '../client';
import { useChallengeBySlug } from '../hooks/useChallengeBySlug';
import useChallengeCompletion from '../hooks/useChallengeCompletion';
import ChallengeCompleteModal from '../components/Challenge/ChallengeCompleteModal';
import { useGenericModal } from '../context/GenericModalContext';
import { useChallenges } from '../hooks/useChallenges';

export default function Challenge() {
  const [editor, setEditor] = useState<Ace.Editor>();
  const { code, setCode } = useCode();
  const { setResetLevel: setResetChallenge } = useOutput();
  const [selectedTab, setSelectedTab] = useState(0);

  const [executionResult, setExecutionResult] = useState<string[]>();
  const [isCodeRunning, setIsCodeRunning] = useState(false);
  const [isExecutionSuccessful, setIsExecutionSuccessful] = useState(false);

  const { setOpenModal: setOpenChallengeCompleteModal } = useGenericModal('challenge-complete');
  const { runScript, isLoading: isPyodideLoading, errorAnnotations, setErrorAnnotations } = usePyodide();
  const { lng, challengeSlug } = useParams();

  const { data } = useChallengeBySlug(challengeSlug, lng);

  const { data: challenges, isLoading } = useChallenges();

  const { updateChallengeCompletion } = useChallengeCompletion();

  useEffect(() => {
    if (data) {
      setCode(data.template);
    }
  }, [data]);

  useEffect(() => {
    if (!isExecutionSuccessful) return;
    if (!challengeSlug) return;

    updateChallengeCompletion.mutateAsync({
      last_status: ChallengeCompletionStatus.COMPLETE,
      challenge_slug: challengeSlug
    });

    setOpenChallengeCompleteModal(true);
  }, [isExecutionSuccessful]);

  if (!data) return null;

  const onCodeExecution = async () => {
    if (!challengeSlug) return;
    await updateChallengeCompletion.mutateAsync({
      last_status: ChallengeCompletionStatus.IN_PROGRESS,
      last_code_executed: code,
      challenge_slug: challengeSlug
    });
  };

  const runCode = async () => {
    if (runScript) {
      setIsExecutionSuccessful(false);
      onCodeExecution();
      setIsCodeRunning(true);
      try {
        await runScript(code, true);
        await runScript('import json');

        const executions: string[] = [];
        let success = true;

        for (const execution of data.executions) {
          const result = await runScript(
            `json.dumps(${data.function_name}(*json.loads("""${execution.input}""")))`
          );

          const resultString = result.results;
          executions.push(resultString);

          if (resultString !== execution.output) {
            success = false;
          }
        }

        setExecutionResult(executions);
        setIsCodeRunning(false);
        setIsExecutionSuccessful(success);
      } catch (error) {
        console.error('Error executing code:', error);
        setIsExecutionSuccessful(false);
      }

      setIsCodeRunning(false);
    }
  };

  const reset = () => {
    setCode(data.template);
    setResetChallenge(true);
  };

  const solution = () => {
    setCode(data.solution);
    setResetChallenge(true);
  };

  const onChange = (newValue: string) => {
    setCode(newValue);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getNextChallenge = () => {
    if (!challenges) return null;
    const currentChallengeIndex = challenges.findIndex((c) => c.slug === challengeSlug);
    if (currentChallengeIndex === -1) return null;
    return challenges[currentChallengeIndex + 1];
  };

  const isExecutionDisabled = isCodeRunning;

  return (
    <Box component="div" sx={{ flex: 1, width: '100%', display: 'flex' }}>
      <Box
        component="div"
        sx={{
          flexDirection: 'column',
          overflowY: 'scroll',
          width: 640,
          minWidth: 640,
          maxWidth: '100%',
          paddingLeft: 3,
          paddingRight: 3,
          display: 'flex',
          height: 'calc(100vh - 64px)'
        }}
      >
        <TipModal />
        <ErrorModal />
        <ChallengeCompleteModal
          nextChallenge={getNextChallenge()?.slug}
          currentChallenge={data.title}
        />
        <ChooseStudentModal />

        <Box component="div">
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="h6" align="center">
              {data.title}
            </Typography>
          </Box>
          <Divider sx={{ mt: 2 }} />

          <Box component="div" className="level-data-markdown" dir="auto">
            <MarkdownSection markdown={data.description} />
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          flex: 1,
          overflow: 'none',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 64px)'
        }}
      >
        <AceEditor
          onLoad={setEditor}
          width="100%"
          minLines={10}
          fontSize={14}
          mode="python"
          theme="monokai"
          onChange={onChange}
          value={code}
          name="game-editor"
          style={{ flex: 1 }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: true,
            tabSize: 4
          }}
          editorProps={{ $blockScrolling: true }}
        />

        <Box component="div" sx={{ display: 'flex', p: 2, gap: 2 }}>
          <Button
            onClick={runCode}
            disabled={isExecutionDisabled}
            variant="contained"
            startIcon={!isCodeRunning && <PlayArrow />}
          >
            {!isCodeRunning ? (
              'Run Code'
            ) : (
              <Box
                component="div"
                sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
              >
                <CircularProgress size={15} />
                Running
              </Box>
            )}
          </Button>
          <Button
            onClick={reset}
            variant="outlined"
            disabled={isExecutionDisabled}
            startIcon={<Refresh />}
          >
            Reset
          </Button>
          <Button
            onClick={solution}
            variant="outlined"
            disabled={isExecutionDisabled}
            startIcon={<QuestionMark />}
          >
            Solution
          </Button>
        </Box>

        <Box component="div">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Execution Tabs"
          >
            {data.executions.map((execution, index) => (
              <Tab key={index} label={`Execution ${index + 1}`} />
            ))}
          </Tabs>

          <Box
            component="div"
            sx={{
              display: 'flex',
              padding: 2,
              gap: 2,
              flexDirection: 'row',
              alignItems: 'stretch',
              height: 250
            }}
          >
            <Box
              component="div"
              sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1, overflow: 'scroll' }}
            >
              <Typography variant="subtitle1">Inputs</Typography>
              <Divider />
              {JSON.parse(data.executions[selectedTab].input).map((i: any, index: number) => (
                <Box
                  component="div"
                  key={JSON.stringify(i)}
                  sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                >
                  <Typography sx={{ fontFamily: 'monospace' }}>Arg {index + 1}:</Typography>
                  <SyntaxHighlighter
                    style={monokaiSublime}
                    wrapLines={true}
                    wrapLongLines={true}
                    PreTag="div"
                    language="python"
                  >
                    {JSON.stringify(i)}
                  </SyntaxHighlighter>
                </Box>
              ))}
            </Box>

            <Box
              component="div"
              sx={{ flex: 2, display: 'flex', flexDirection: 'column', gap: 1, overflow: 'scroll' }}
            >
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  overflow: 'scroll'
                }}
              >
                <Typography variant="subtitle1">Expected Output</Typography>
                <SyntaxHighlighter
                  style={monokaiSublime}
                  customStyle={{ flex: 1 }}
                  PreTag="div"
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {`${data.executions[selectedTab].output}`}
                </SyntaxHighlighter>
              </Box>
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  overflow: 'scroll'
                }}
              >
                <Typography variant="subtitle1">Actual Output</Typography>
                <SyntaxHighlighter
                  style={monokaiSublime}
                  customStyle={{ flex: 1 }}
                  PreTag="div"
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {executionResult ? executionResult[selectedTab] : ' '}
                </SyntaxHighlighter>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
