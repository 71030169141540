import React from 'react';
import { Box, IconButton, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { CourseData } from '../client';
import LockIcon from '@mui/icons-material/Lock';
import { useLocaleContext } from '../context/LocaleContext';

interface ProgressTooltipProps {
  completed: number;
  incomplete: number;
  locked: number;
}

const ProgressTooltip = ({ completed, incomplete, locked }: ProgressTooltipProps) => (
  <div>
    <Typography variant="body2">Completed: {completed}</Typography>
    <Typography variant="body2">Incomplete: {incomplete}</Typography>
    <Typography variant="body2">Locked: {locked}</Typography>
  </div>
);

export default function CourseProgress({ courseData }: { courseData: CourseData }) {
  const theme = useTheme();

  const { getLocaleLink } = useLocaleContext();

  let totalCourses = 0;
  let completedCourses = 0;
  let lockedCourses = 0;

  for (const chapter of courseData.chapters) {
    for (const level of chapter.level_data) {
      totalCourses++;
      if (level.locked) {
        lockedCourses++;
      } else if (level.completed) {
        completedCourses++;
      }
    }
  }

  const incompleteCourses = totalCourses - completedCourses - lockedCourses;

  const completedPercentage = (completedCourses / totalCourses) * 100;
  const lockedPercentage = (lockedCourses / totalCourses) * 100;
  const incompletePercentage = (incompleteCourses / totalCourses) * 100;

  return (
    <Stack gap={1}>
      <Tooltip
        title={
          <ProgressTooltip
            completed={completedCourses}
            incomplete={incompleteCourses}
            locked={lockedCourses}
          />
        }
        arrow
      >
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          width="100%"
          height={4}
          component="div"
        >
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              backgroundColor: theme.palette.primary.main,
              width: `${completedPercentage}%`,
              zIndex: 1
            }}
            component="div"
          />
          {lockedPercentage > 0 && (
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                background: `repeating-linear-gradient(
                -45deg,
                ${theme.palette.grey[400]},
                ${theme.palette.grey[400]} 5px,
                ${theme.palette.grey[700]} 5px,
                ${theme.palette.grey[700]} 10px
              )`,
                width: `${lockedPercentage}%`,
                right: 0,
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.common.white,
                fontWeight: 'bold'
              }}
              component="div"
            >
              <Link href={getLocaleLink("/purchase")} underline="none">
                <IconButton
                  sx={{
                    width: 28,
                    height: 28,
                    borderRadius: 28,
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <LockIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </Link>
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              backgroundColor: theme.palette.grey[400],
              width: `${incompletePercentage}%`,
              left: `${completedPercentage}%`,
              zIndex: 3
            }}
            component="div"
          />
        </Box>
      </Tooltip>
      <Typography variant="body2">
        {completedCourses} of {totalCourses} levels completed
      </Typography>
    </Stack>
  );
}
