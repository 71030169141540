import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import { addCompleter } from 'ace-builds/src-noconflict/ext-language_tools';

import React, { useContext, useEffect, useState } from 'react';
import { useOutput } from '../context/OutputContext';
import { Button, Box, CircularProgress, Typography } from '@mui/material';

import { FileUpload, PlayArrow, Refresh, Save, Stop } from '@mui/icons-material';
import usePyodide from '../hooks/usePyodide';
import waitForMs from '../@core/utils/waitForMs';
import { useGenericModal } from '../context/GenericModalContext';
import { useCode } from '../context/CodeContext';
import SaveCodeModal from './SaveCodeModal';
import { CourseData } from '../client';
import LoadCodeModal from './LoadCodeModal';
import { useParams } from 'react-router-dom';
import { Ace } from 'ace-builds';
import { UserContext } from '../pages/App';
import CodeAdder from './CodeAdder';
import TipBox from './TipBox';
import { useTipContext } from '../context/TipProvider';
import { useTranslation } from 'react-i18next';

interface CodeEditorProps {
  codeTemplate: string;
  setMobileToggle: React.Dispatch<React.SetStateAction<'code' | 'game'>>;
  courseData?: CourseData;
  onCodeExecution: () => void;
  maxLinesOfCode?: number;
  chapterId: number;
}

export default function CodeEditor({
  codeTemplate,
  setMobileToggle,
  courseData,
  onCodeExecution,
  maxLinesOfCode,
  chapterId
}: CodeEditorProps) {
  const userContext = useContext(UserContext);
  const [editor, setEditor] = useState<Ace.Editor>();
  const { levelSlug } = useParams();
  const { code, setCode } = useCode();
  const { setOpenModal: setOpenSaveCodeModal } = useGenericModal('saveCode');
  const { setOpenModal: setOpenLoadCodeModal } = useGenericModal('loadCode');
  const { setOpenModal: setOpenErrorModal, setContent: setErrorModalContent } =
    useGenericModal('error');

  const { setTip, setTipVisible, nextTip, currentTipIndex, setTipOpen } = useTipContext();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (levelSlug === 'intro-level1' || levelSlug === undefined) {
        setTipVisible(true);
        setTip('run-first');
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (courseData) {
      const chapterData = courseData.chapters[chapterId]?.level_data;
      if (chapterData) {
        const currentLevelData = chapterData.find((level) => level.slug === levelSlug);
        setCode(currentLevelData?.last_code_executed || codeTemplate);
      }
    }
  }, [codeTemplate, courseData, chapterId, levelSlug, setCode]);

  const {
    runScript, 
    isLoading: isPyodideLoading,
    errorAnnotations,
    setErrorAnnotations,
    isCodeRunning,
    setIsCodeRunning,
    stopCodeExecution
  } = usePyodide();
  const {
    isCodeExecuted,
    setIsCodeExecuted,
    setReturnToStart,
    setResetLevel,
    firstExecution,
    setFirstExecution,
    commandsInQueue,
    resetCommandsQueue
  } = useOutput();

  useEffect(() => {
    if (!isCodeRunning && !firstExecution && currentTipIndex === 0) {
      nextTip();
    }
  }, [isCodeRunning]);

  const countNonCommentNonEmptyLines = (code: string): number => {
    return code.split('\n').filter((line) => line.trim() !== '' && !line.trim().startsWith('#'))
      .length;
  };

  useEffect(() => {
    if (!isCodeExecuted) return;
    setIsCodeRunning(commandsInQueue.length !== 0);
  }, [commandsInQueue]);

  const runCode = async () => {
    setTipOpen(false);
    if (
      maxLinesOfCode !== undefined &&
      maxLinesOfCode !== null &&
      countNonCommentNonEmptyLines(code) > maxLinesOfCode
    ) {
      setErrorModalContent(t('codeEditor.exceededMaxLines', { maxLines: maxLinesOfCode }));
      setOpenErrorModal(true);
      return;
    }

    setMobileToggle('game');
    setErrorAnnotations([]);
    onCodeExecution();

    if (runScript) {
      if (!firstExecution) {
        setResetLevel(true);
      } else {
        setFirstExecution(false);
      }
      setIsCodeExecuted(false);
      await waitForMs(200);
      setReturnToStart(true);
      await waitForMs(500);

      try {
        await runScript(code, true);
      } catch (error) {
        stopCodeExecution();
        console.error('Error running script:', error);
      }
      
      setIsCodeExecuted(true);
    }
  };

  const stop = () => {
    resetCommandsQueue();
    setIsCodeRunning(false);
    setIsCodeExecuted(true);
  };

  const onChange = (newValue: string) => {
    setCode(newValue);
  };

  const reset = () => {
    setCode(codeTemplate);
    setResetLevel(true);
  };

  const loadCode = (code: string) => {
    setCode(code);
    setOpenLoadCodeModal(false);
  };

  useEffect(() => {
    if (!editor) return;
    editor.getSession().setAnnotations(errorAnnotations);
  }, [errorAnnotations]);

  useEffect(() => {
    // data stub:
    const sqlTables = [
      { name: 'move_forward()', description: 'Move forward' },
      { name: 'turn_left()', description: 'Turn left' },
      { name: 'turn_right()', description: 'Turn right' },
      { name: 'push()', description: 'Push an object' },
      { name: 'build()', description: 'Build an object' },
      { name: 'speak()', description: 'Speak text' },
      { name: 'open()', description: 'Open an object' },
      { name: 'close()', description: 'Close an object' },
      { name: 'water()', description: 'Water an object' },
      { name: 'collect()', description: 'Collect an object' },
      { name: 'place()', description: 'Place an object' },
      { name: 'combine()', description: 'Combine objects' }
    ];

    const sqlTablesCompleter = {
      getCompletions: (
        editor: Ace.Editor,
        session: Ace.EditSession,
        pos: Ace.Point,
        prefix: string,
        callback: Ace.CompleterCallback
      ): void => {
        callback(
          null,
          sqlTables.map(
            (table) =>
              ({
                caption: `${table.name}: ${table.description}`,
                value: table.name,
                meta: 'function'
              }) as Ace.Completion
          )
        );
      }
    };
    addCompleter(sqlTablesCompleter);
  }, []);

  if ((isPyodideLoading && !runScript) || !courseData) {
    return <CircularProgress />;
  }

  const isExecutionDisabled =
    !isCodeExecuted || (userContext && !userContext?.student_id) || isCodeRunning || isPyodideLoading;

  const addCode = () => {
    if (!editor) {
      return;
    }
    editor.gotoLine(10000, 0, true);
    editor.insert('player.move_forward()');
    nextTip();
  };

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flex: 1, py: 2 }}>
      <Box component="div" sx={{ my: 2, ml: 5.5, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <Box component="div" sx={{ position: 'relative' }}>
          <Button
            onClick={runCode}
            disabled={isExecutionDisabled}
            variant="contained"
            startIcon={!isCodeRunning && <PlayArrow />}
          >
            {!isCodeRunning ? (
              t('codeEditor.runCode')
            ) : (
              <Box
                component="div"
                sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
              >
                <CircularProgress size={15} />
                {t('codeEditor.running')}
              </Box>
            )}
          </Button>
          <TipBox
            sx={{
              position: 'absolute',
              left: '100%',
              bottom: 0,
              width: {
                xs: '170px',
                md: '300px'
              },
              zIndex: 10
            }}
            name="run-first"
          >
            <Typography dir="auto">{t('codeEditor.tipRunFirst')}</Typography>
          </TipBox>
          <TipBox
            sx={{
              position: 'absolute',
              left: '100%',
              bottom: 0,
              minWidth: {
                xs: '200px',
                md: '300px'
              },
              zIndex: 10
            }}
            name="run-again"
          >
            <Typography dir="auto">{t('codeEditor.tipRunAgain')}</Typography>
          </TipBox>
        </Box>
        {isCodeRunning && (
          <Button
            onClick={stop}
            variant="outlined"
            color="error"
            startIcon={<Stop />}
          >
            {/* TODO: add locales */}
            {/* {t('codeEditor.stop')} */}
            STOP
          </Button>
        )}
        <Button
          onClick={reset}
          variant="outlined"
          disabled={isExecutionDisabled}
          startIcon={<Refresh />}
        >
          {t('codeEditor.reset')}
        </Button>
        <Button
          onClick={() => {
            setOpenSaveCodeModal(true);
          }}
          disabled={isExecutionDisabled}
          variant="outlined"
          startIcon={<Save />}
        >
          {t('codeEditor.save')}
        </Button>
        <SaveCodeModal code={code} />
        <Button
          onClick={() => {
            setOpenLoadCodeModal(true);
          }}
          disabled={isExecutionDisabled}
          variant="outlined"
          startIcon={<FileUpload />}
        >
          {t('codeEditor.load')}
        </Button>
        <LoadCodeModal onLoad={loadCode} />
      </Box>
      <Box component="div" sx={{ position: 'relative' }}>
        <AceEditor
          onLoad={setEditor}
          width="100%"
          height="400px"
          fontSize={16}
          mode="python"
          theme="monokai"
          onChange={onChange}
          value={code}
          name="game-editor"
          // add completer: https://github.com/securingsincity/react-ace/issues/338
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: true,
            tabSize: 4
          }}
          editorProps={{ $blockScrolling: true }}
        />
        <CodeAdder
          codeToAdd="player.move_forward()"
          sx={{
            position: 'absolute',
            top: 50,
            left: {
              xs: 50,
              md: 200
            }
          }}
          onClick={addCode}
        />
      </Box>
    </Box>
  );
}
