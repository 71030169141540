import { useMutation } from 'react-query';
import { PaymentService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useLocaleContext } from '../context/LocaleContext';

export const useUpgradeToOrg = () => {
  const { createSnackBar } = useSnackBarContext();

  const { getLocaleLink } = useLocaleContext();

  const upgradeToOrgMutation = useMutation(
    PaymentService.upgradeToOrganizationApiPaymentUpgradeToOrganizationGet,
    {
      onSuccess: () => {
        createSnackBar({
          content: 'Successfully upgraded to organization',
          severity: 'success',
          autoHide: true
        });
        // hard reload is needed to update the user context
        window.location.href = getLocaleLink('/organization/details');
      },
      onError: () => {
        createSnackBar({
          content: 'Failed to upgrade to organization',
          severity: 'error',
          autoHide: true
        });
      }
    }
  );
  return upgradeToOrgMutation;
};
