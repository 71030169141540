import { useQuery } from 'react-query';
import { ChallengeService } from '../client';

export const useChallengeBySlug = (challengeSlug?: string, lng?: string) => {
  const result = useQuery(
    `challenge-${challengeSlug}-${lng}`,
    () => ChallengeService.getChallengeApiChallengeLangSlugGet(challengeSlug ?? '', lng ?? ''),
    {
      enabled: !!challengeSlug && !!lng
    }
  );

  return result;
};
