import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { classSchema } from '../components/Organization/Class/classSchema';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AuthService,
  Class,
  CreateClassForm,
  LoginForm,
  LoginResponse,
  OrganizationService
} from '../client';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useLocaleContext } from '../context/LocaleContext';

export const useOrgClasses = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { createSnackBar } = useSnackBarContext();
  const { getLocaleLink } = useLocaleContext();

  const { data: classes, isLoading: isClassesLoading } = useQuery(
    ['classes'],
    OrganizationService.getClassesByOrganizationApiOrganizationClassesGet,
    {
      retry: false
    }
  );

  const addClass = useMutation(OrganizationService.createClassApiOrganizationClassesPost, {
    onSuccess: () => {
      queryClient.invalidateQueries('classes');
      navigate(getLocaleLink('/organization/classes'));
      createSnackBar({
        content: 'New Class created successfully.',
        autoHide: true,
        severity: 'success'
      });
    },
    onError: (error: AxiosError) => {
      if (error.status === 409) {
        createSnackBar({
          content: 'Class already exists.',
          autoHide: true,
          severity: 'error'
        });
      } else {
        createSnackBar({
          content: 'Adding class failed',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  });

  const updateClass = useMutation<Class, AxiosError, { id: string; data: CreateClassForm }>(
    ({ id, data }) => OrganizationService.updateClassApiOrganizationClassesClassIdPut(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['classes']);
        navigate(getLocaleLink('/organization/classes'));
      },
      onError: (error: AxiosError) => {
        if (error.status === 404) {
          createSnackBar({
            content: 'Class not found.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 409) {
          createSnackBar({
            content: 'Class already exists.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 401) {
          createSnackBar({
            content: 'Permission denied.',
            autoHide: true,
            severity: 'error'
          });
        } else {
          createSnackBar({
            content: 'Editing class failed, please contact support.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  const deleteClass = useMutation<Class, AxiosError, string>(
    (id: string) => OrganizationService.deleteClassApiOrganizationClassesClassIdDelete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['classes']);
        navigate(getLocaleLink('/organization/classes'));
      },
      onError: (error: AxiosError) => {
        if (error.status === 404) {
          createSnackBar({
            content: 'Class not found.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 401) {
          createSnackBar({
            content: 'Permission denied.',
            autoHide: true,
            severity: 'error'
          });
        } else {
          createSnackBar({
            content: 'Deleting class failed, please contact support.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  return { classes, isClassesLoading, addClass, updateClass, deleteClass };
};
