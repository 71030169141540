import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCurrentLocale } from '../../hooks/useCurrentLocale';
import { languages } from './languages';

export const LocaleRedirect = () => {
  const { data: locale, isLoading } = useCurrentLocale();
  const { lng } = useParams();
  const { pathname } = useLocation();

  const selectedLanguage = languages.find((lang) => lang.name === locale?.language) || languages[0];
  
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!lng) {
      const newPathname = `/${selectedLanguage.code}${pathname}`;
      if (!pathname.startsWith(`/${selectedLanguage.code}`)) {
        window.location.href = newPathname;
      }
    }
  }, [lng, locale, selectedLanguage.code, selectedLanguage.name, pathname]);

  if (isLoading) {
    return null;
  }

  return null;
};
