import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ProfileService, ProfileUpdateForm } from '../../client';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { Loader } from '../Loader';
import { useContext, useState } from 'react';
import { UserContext } from '../../pages/App';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateProfileSchema } from './updateProfileSchema';
import { useConfirm } from 'material-ui-confirm';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocaleContext } from '../../context/LocaleContext';

export const AddEditProfileDetails = () => {
  const { createSnackBar } = useSnackBarContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const currentUser = useContext(UserContext);

  const { getLocaleLink } = useLocaleContext();

  const { data: profileData } = useQuery('profile', ProfileService.getProfileApiProfileGet);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(updateProfileSchema),
    mode: 'onChange'
  });

  const updateProfileMutation = useMutation(
    (profileUpdate: ProfileUpdateForm) => ProfileService.updateProfileApiProfilePost(profileUpdate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        createSnackBar({
          content: 'Profile details updated successfully.',
          autoHide: true,
          severity: 'success'
        });
        navigate(getLocaleLink('/profile/details'));
      },
      onError: () => {
        createSnackBar({ content: 'Editing profile failed', autoHide: true, severity: 'error' });
      }
    }
  );

  const deleteProfile = async () => {
    // confirm deletion
    try {
      await confirm({
        description: 'Are you sure you want to delete your profile and close your account?',
        title: 'Delete Profile',
        confirmationText: 'Yes',
        cancellationText: 'No'
      });
    } catch (error) {
      return;
    }

    await deleteProfileMutation.mutateAsync();
  };

  const deleteProfileMutation = useMutation(ProfileService.deleteProfileApiProfileDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries('profile');
      createSnackBar({
        content: 'Profile deleted successfully.',
        autoHide: true,
        severity: 'success'
      });
      // hard refresh
      window.location.href = getLocaleLink('/');
    },
    onError: () => {
      createSnackBar({ content: 'Deleting profile failed', autoHide: true, severity: 'error' });
    }
  });

  const onSubmit = async (data: ProfileUpdateForm) => {
    await updateProfileMutation.mutateAsync(data);
  };

  if (!profileData) return <Loader />;

  return (
    <Paper sx={{ p: 5, mt: 4 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="div" display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>
              <Stack gap={4} flex={1}>
                <Controller
                  name="full_name"
                  control={control}
                  defaultValue={profileData.full_name}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={!!errors.full_name}
                      helperText={errors.full_name?.message}
                      fullWidth
                      label="Full Name"
                      margin="none"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      fullWidth
                      label={!currentUser ? 'Password' : 'New password (optional)'}
                      margin="none"
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      label="Confirm new password"
                      fullWidth
                      margin="none"
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" display="flex" mt={2} gap={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !!errors.confirmPassword}
              >
                {isSubmitting || updateProfileMutation.isLoading ? 'Saving...' : 'Save'}
              </Button>
              <Button type="button" onClick={deleteProfile} variant="contained" color="error">
                {isSubmitting || deleteProfileMutation.isLoading
                  ? 'Deleting Profile'
                  : 'Delete Profile'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
