import remarkGfm from 'remark-gfm';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Markdown from 'react-markdown';

export default function MarkdownSection({ markdown }: { markdown: string }) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        blockquote: ({ node, ...props }) => (
          <blockquote style={{ borderLeft: '4px solid #fff', paddingLeft: '16px' }} {...props} />
        ),
        code({ node, inline, className, children, ...props }: any) {
          const match = /language-(\w+)/.exec(className || '');

          return !inline && match ? (
            <SyntaxHighlighter
              style={monokaiSublime}
              PreTag="div"
              language={match[1]}
              customStyle={{
                direction: 'ltr'
              }}
              {...props}
            >
              {children.replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code
              style={{
                backgroundColor: '#47483f',
                padding: '1px 4px',
                borderRadius: '4px',
                direction: 'ltr'
              }}
              {...props}
            >
              {children}
            </code>
          );
        }
      }}
    >
      {markdown}
    </Markdown>
  );
}
