import { createContext, useContext, ReactNode } from 'react';
import { useCurrentLocale } from '../hooks/useCurrentLocale';
import { languages } from '../components/LocaleSelect/languages';

type LocaleContextType = {
  locale: string | undefined;
  getLocaleLink: (path: string) => string;
};

const LocaleContext = createContext<LocaleContextType>({
  locale: 'en',
  getLocaleLink: () => '/en'
});

interface LocaleProviderProps {
  children: ReactNode;
}

export function LocaleProvider({ children }: LocaleProviderProps) {
  const { data: locale } = useCurrentLocale();

  const localeCode = languages.find(
    (lang) => lang.name.toLowerCase() === locale?.language.toLowerCase()
  )?.code;

  const getLocaleLink = (path: string) => {
    return `/${localeCode || 'en'}${path}`;
  };

  return (
    <LocaleContext.Provider value={{ locale: localeCode, getLocaleLink }}>
      {children}
    </LocaleContext.Provider>
  );
}

export function useLocaleContext(): LocaleContextType {
  return useContext(LocaleContext);
}
