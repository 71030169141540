/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChallengeItem = {
    title: string;
    difficulty: ChallengeItem.difficulty;
    slug: string;
    completed?: boolean;
};

export namespace ChallengeItem {

    export enum difficulty {
        BEGINNER = 'beginner',
        ADVANCED = 'advanced',
        EXPERT = 'expert',
    }


}

