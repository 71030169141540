import React, { useEffect, useState } from 'react';
import { Box, DialogContent, DialogContentText } from '@mui/material';
import { Login, KeyboardDoubleArrowRight, Close } from '@mui/icons-material';
import { LottieRefCurrentProps, useLottie } from 'lottie-react';
import useLevels from '../useLevels';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useOutput } from '../../context/OutputContext';
import { useGenericModal } from '../../context/GenericModalContext';
import { useLocaleContext } from '../../context/LocaleContext';
import LevelCompleteAnimation from '../../resources/animations/level-complete.json';
import { useCode } from '../../context/CodeContext';
import Modal3D from '../GameUI/Modal3D';
import RibbonHeader from '../GameUI/RibbonHeader';
import PrimaryButton from '../GameUI/PrimaryButton';
import SecondaryButton from '../GameUI/SecondaryButton';

interface ChallengeCompleteModalProps {
  nextChallenge?: string;
  currentChallenge?: string;
}

const lottieStyle: { [key: string]: unknown } = {
  pointerEvents: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1400,
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  height: '700px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const ChallengeCompleteModal: React.FC<ChallengeCompleteModalProps> = ({
  nextChallenge,
  currentChallenge
}) => {
  const { courseId } = useLevels();
  const { currentUser } = useCurrentUser();
  const { openModal: open, setOpenModal: setOpenChallengeCompleteModal } =
    useGenericModal('challenge-complete');
  const { setResetLevel } = useOutput();
  const { setCode } = useCode();

  const { getLocaleLink } = useLocaleContext();

  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  const { View: LottieView, goToAndPlay } = useLottie({
    animationData: LevelCompleteAnimation,
    loop: false,
    autoplay: false,
    style: lottieStyle,
    lottieRef
  });

  const [fontSize, setFontSize] = useState(28);

  useEffect(() => {
    const calculateFontSize = () => {
      if (currentChallenge) {
        const charCount = currentChallenge.length;
        const baseFontSize = 28;
        const minFontSize = 12;
        const scaleRatio = 0.2;

        const newFontSize = Math.max(baseFontSize - charCount * scaleRatio, minFontSize);
        setFontSize(newFontSize);
      }
    };

    calculateFontSize();
  }, [currentChallenge]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        goToAndPlay(0);
      }, 10);
    }
  }, [open]);

  const handleClose = (_event: React.SyntheticEvent, reason: string) => {
    if (reason === 'backdropClick') return;
    setOpenChallengeCompleteModal(false);
  };

  if (!nextChallenge || !currentChallenge) return null;
  return (
    <>
      <Box component="div">{LottieView}</Box>
      <Modal3D
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        disableEscapeKeyDown
        sx={{ '& .MuiDialog-paper': { width: '350px', userSelect: 'none', minHeight: 380 } }}
      >
        <RibbonHeader>YOU MADE IT!</RibbonHeader>
        <DialogContent
          sx={{ padding: '12px', display: 'flex', alignItems: 'start', justifyContent: 'center' }}
        >
          <DialogContentText
            sx={{
              fontFamily: 'LDFComicSansBold',
              textTransform: 'uppercase',
              textAlign: 'center',
              color: '#F1E5C6',
              textShadow: '2px 2px 4px #A37B5E',
              webkitTextStroke: '1px #A37B5E',
              fontSize: `${fontSize}px`,
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              height: 68,
              wordWrap: 'break-word'
            }}
          >
            {currentChallenge}
          </DialogContentText>
        </DialogContent>
        <div className="ribbonRounded">
          {'Complete'.split('').map((char, index) => (
            <span key={index} className="char" style={{ animationDelay: `${1.6 + 0.1 * index}s ` }}>
              {char}
            </span>
          ))}
        </div>

        <Box component="div" sx={{ p: '20px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <SecondaryButton
            onClick={(e) => handleClose(e, 'buttonClick')}
            sx={{
              width: '100%'
            }}
            startIcon={<Close />}
          >
            Close
          </SecondaryButton>
          {!currentUser && (
            <PrimaryButton
              href={getLocaleLink('/signup')}
              sx={{
                width: '100%'
              }}
              startIcon={<Login />}
            >
              Sign Up
            </PrimaryButton>
          )}
        </Box>
        <Box
          component="div"
          sx={{ px: '20px', pb: '20px', display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <PrimaryButton
            href={getLocaleLink(`/challenge/${nextChallenge}`)}
            sx={{
              width: '100%'
            }}
            endIcon={<KeyboardDoubleArrowRight sx={{ mt: '3px' }} />}
          >
            Next Challenge
          </PrimaryButton>
        </Box>
      </Modal3D>
    </>
  );
};

export default ChallengeCompleteModal;
