import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  useTheme
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import codingforkids from '../assets/codingforkids-cat.png';
import { AuthService, SignupForm } from '../client';
import { GoogleIcon } from '../components/GoogleIcon';
import ProfileImageWithSelection from '../components/Profile/ProfileImageWithSelection';
import { Controller, FieldError } from 'react-hook-form';
import { useSignupForm } from '../hooks/useSignupForm';
import { Loader } from '../components/Loader';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { schoolTypes } from '../schoolData';
import { useLocaleContext } from '../context/LocaleContext';

interface SignupFormData extends SignupForm {
  verify_password: string;
}

export const USER_TYPES = {
  parent: 0,
  teacher: 1,
  admin: 2
};

const orgNameHelperText = "Please enter your organization's full name in English.";
const orgSlugHelperText =
  "Please enter a unique identifier for your organization, using only lowercase letters and dashes. The slug will be used in the log in link for your organization's page.";
const orgTypeHelperText = 'Please select the type of organization you are signing up as.';
const orgEmailDomainHelperText =
  "Please enter the email domain for your organization. This will be used to verify that users signing up with your organization's email address are part of your organization.";

export default function SignupPage() {
  const {
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    handleSubmit,
    trigger,
    setError,
    reset
  } = useSignupForm();

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();
  const confirm = useConfirm();
  const userType = watch('user_type');

  const { getLocaleLink } = useLocaleContext();

  const { data: signUpUser, isLoading } = useQuery(
    ['user', token],
    () => AuthService.getUserByTokenApiAuthGetUserByTokenGet(token!),
    {
      enabled: !!token,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );

  useEffect(() => {
    if (signUpUser?.user_type === USER_TYPES.teacher) {
      reset({
        email: signUpUser.email,
        user_type: signUpUser.user_type
      });
    }
  }, [signUpUser]);

  const signUpMutation = useMutation(
    (requestBody: SignupForm) => AuthService.signupApiAuthSignupPost(token || '', requestBody),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currentUser');
        if (userType === USER_TYPES.teacher) {
          navigate(getLocaleLink('/organization/students'));
          return;
        } else if (userType === USER_TYPES.admin) {
          navigate(getLocaleLink('/organization/teachers'));
          return;
        }
        navigate(getLocaleLink('/'));
      },
      onError: async (error: AxiosError) => {
        if (error.response && error.response.status === 409) {
          await confirm({
            title: 'Error',
            description: 'E-mail already exists!'
          });
        } else {
          await confirm({
            title: 'Error',
            description: 'Error signing up. Please try again.'
          });
        }
      }
    }
  );

  const triggerWithApi = async () => {
    const manualErrors = Object.entries(errors).filter(([_, value]) => value.type === 'manual');
    await trigger();
    manualErrors.forEach(([key, value]) => {
      setError(key as never, value as FieldError);
    });
  };

  const onSubmitWrapper = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValid) {
      await triggerWithApi();
    }
    if (isValid && Object.keys(errors).length === 0) {
      handleSubmit(onSubmit)(event);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showStudentPassword, setShowStudentPassword] = useState(false);
  const theme = useTheme();

  const isParent = Number(userType) === USER_TYPES.parent;
  const isTeacher = token && signUpUser?.user_type === USER_TYPES.teacher;
  const isAdmin = Number(userType) === USER_TYPES.admin;

  const onSubmit = (data: SignupFormData) => {
    if (isTeacher) {
      signUpMutation.mutate({
        email: data.email,
        password: data.password,
        full_name: data.full_name,
        user_type: Number(data.user_type)
      });
      return;
    }
    signUpMutation.mutate({
      email: data.email,
      password: data.password,
      full_name: data.full_name,
      student_full_name: data.student_full_name,
      student_username: data.student_username,
      student_password: data.student_password,
      user_type: Number(data.user_type),
      organization_name: data.organization_name,
      organization_slug: data.organization_slug,
      organization_type: data.organization_type,
      organization_email_domain: data.organization_email_domain,
      character: data.character
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isTeacher) {
    return (
      <Container
        component="main"
        maxWidth="sm"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
      >
        <Paper
          sx={{
            p: 3,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img src={codingforkids} height={64} />
          <Typography component="h1" variant="h5" sx={{ py: 2 }}>
            Sign Up to Coding for Kids
          </Typography>

          <Box component="form" onSubmit={onSubmitWrapper} noValidate sx={{ mt: 1 }}>
            <Grid container columnSpacing={1} sx={{ width: '100%' }}>
              <Grid item xs={12}>
                <Button
                  href="/api/auth/google/authorize"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 1 }}
                  startIcon={<GoogleIcon />}
                >
                  Sign In with Google
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="full_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      fullWidth
                      id="fullName"
                      label="Full Name"
                      autoComplete="fullName"
                      error={!!errors.full_name}
                      helperText={errors.full_name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={signUpUser?.email}
                  disabled
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      autoComplete="email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      fullWidth
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="verify_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      fullWidth
                      label="Verify Password"
                      type={showPassword ? 'text' : 'password'}
                      id="verifyPassword"
                      error={!!errors.verify_password}
                      helperText={errors.verify_password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
              disabled={isSubmitting || (signUpMutation.isLoading && !signUpMutation.isError)}
            >
              {isSubmitting || (signUpMutation.isLoading && !signUpMutation.isError)
                ? 'Signing Up...'
                : 'Sign Up'}
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
    >
      <Paper
        sx={{
          p: 3,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={codingforkids} height={64} />
        <Typography component="h1" variant="h5" sx={{ py: 2 }}>
          Sign Up to Coding for Kids
        </Typography>
        <Typography variant="body2">
          You must be 13 years or older to sign up. Children under the age of 13 must be supervised
          by a parent or guardian. Please read our <a href="/terms-of-service">Terms of Service</a>{' '}
          for more information.
        </Typography>

        <Box component="form" onSubmit={onSubmitWrapper} noValidate sx={{ mt: 1 }}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Button
                href="/api/auth/google/authorize"
                fullWidth
                variant="contained"
                size="large"
                startIcon={<GoogleIcon />}
                sx={{ mt: 1 }}
              >
                Sign In with Google
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    autoComplete="fullName"
                    error={!!errors.full_name}
                    helperText={errors.full_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="verify_password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Verify Password"
                    type={showPassword ? 'text' : 'password'}
                    id="verifyPassword"
                    error={!!errors.verify_password}
                    helperText={errors.verify_password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle verify password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12}>
              <Controller
                name="user_type"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="user-type-label">I am a:</FormLabel>
                    <RadioGroup
                      {...field}
                      aria-labelledby="user-type-label"
                    >
                      <FormControlLabel
                        value={USER_TYPES.parent}
                        control={<Radio />}
                        label="Parent"
                      />
                      <FormControlLabel
                        value={USER_TYPES.admin}
                        control={<Radio />}
                        label="Organization Administrator (School, etc)"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          {isParent && (
            <Grid container mt={2}>
              <Grid item xs={12}>
                <Controller
                  name="student_full_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label="Student Full Name"
                      type="text"
                      error={!!errors.student_full_name}
                      helperText={errors.student_full_name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid container columnSpacing={2} mt={2}>
                <Grid item xs="auto">
                  <Controller
                    name="character"
                    control={control}
                    defaultValue={'Rabbit'}
                    render={({ field: { value, onChange } }) => (
                      <ProfileImageWithSelection
                        selectedAvatar={value ?? 'Rabbit'}
                        setSelectedAvatar={onChange}
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid direction={'column'} item xs>
                  <Grid item>
                    <Controller
                      name="student_username"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          label="Student Username"
                          type="text"
                          error={!!errors.student_username}
                          helperText={errors.student_username?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name="student_password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="normal"
                          fullWidth
                          label="Student Password"
                          type={showStudentPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowStudentPassword((prev) => !prev)}
                                  edge="end"
                                  sx={{ color: theme.palette.text.secondary }}
                                >
                                  {showStudentPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isAdmin && (
            <Grid container columnSpacing={1}>
              <Grid item xs={12} md={12}>
                <Controller
                  name="organization_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      fullWidth
                      label="Organization Name"
                      type="text"
                      placeholder={'e.g. "Coding for Kids School"'}
                      error={!!errors.organization_name}
                      helperText={errors.organization_name?.message || orgNameHelperText}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="organization_slug"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      margin="normal"
                      fullWidth
                      label="Organization Slug"
                      type="text"
                      inputProps={{ pattern: '[a-z\\-]{3,}' }}
                      placeholder={'e.g. "coding-for-kids-school"'}
                      error={!!errors.organization_slug}
                      helperText={errors.organization_slug?.message || orgSlugHelperText}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="organization_type"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      margin="normal"
                      required
                      fullWidth
                      label="Organization Type"
                      type="text"
                      error={!!errors.organization_type}
                      helperText={errors.organization_type?.message || orgTypeHelperText}
                    >
                      {schoolTypes.map((schoolType) => (
                        <MenuItem key={schoolType.value} value={schoolType.value}>
                          {schoolType.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="organization_email_domain"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      required
                      label="Organization Email Domain"
                      type="text"
                      inputProps={{ pattern: '[\\w\\-\\.]{3,}' }}
                      error={!!errors.organization_email_domain}
                      helperText={
                        errors.organization_email_domain?.message || orgEmailDomainHelperText
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3 }}
            size="large"
            disabled={isSubmitting || signUpMutation.isLoading}
          >
            {isSubmitting || signUpMutation.isLoading ? 'Signing Up...' : 'Sign Up'}
          </Button>
          {signUpMutation.isError && (
            <Typography color="error" variant="body2">
              Error signing up. Please try again.
            </Typography>
          )}
          <Grid container>
            <Grid item xs>
              <Typography variant="body2">
                Already have an account? <NavLink to={getLocaleLink("/login")}>Log in</NavLink>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
