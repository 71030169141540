import { useQuery } from 'react-query';
import { ProfileService } from '../client';
import useLevels from '../components/useLevels';

export const useStudentsWithoutPurchase = () => {
  const { courseId } = useLevels();
  const result = useQuery(
    'students-without-purchase',
    () =>
      ProfileService.getStudentsWithoutPurchasedCourseApiProfileCourseSlugStudentsNotPurchasedGet(
        courseId
      ),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
  return result;
};
