import { Box, Divider, ListItemIcon, Paper } from '@mui/material';
import { Container, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AddCard,
  PersonSharp,
  SupervisorAccountSharp,
  CorporateFare,
  Groups,
  Class
} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import { UserContext, UserTypeMap } from '../App';
import Logout from '@mui/icons-material/Logout';
import { AuthService, UserType } from '../../client';
import { useMutation, useQueryClient } from 'react-query';
import { useOrganization } from '../../hooks/useOrganization';
import UpgradeToOrgModal from '../../components/Profile/UpgradeToOrgModal';
import { useLocaleContext } from '../../context/LocaleContext';

export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useContext(UserContext);
  const { currentOrganization } = useOrganization();
  const isActive = (path: string) => location.pathname.includes(path);
  const queryClient = useQueryClient();

  const { getLocaleLink } = useLocaleContext();

  const logOut = useMutation(AuthService.logoutApiAuthLogoutGet, {
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
      document.location.href = getLocaleLink('/');
    }
  });

  if (!currentUser) return null;

  if (currentUser.is_student) {
    navigate(getLocaleLink('/'));
    return null;
  }

  const userType = UserTypeMap[currentUser.user_type || UserType._0];
  if (!userType) return null;

  return (
    <>
      <Paper
        component="aside"
        sx={{
          position: 'fixed',
          width: '300px',
          height: '100vh',
          display: { xs: 'none', md: 'block' }
        }}
      >
        {(userType === 'admin' || userType === 'teacher') && (
          <>
            <Typography sx={{ px: 1, pt: 1 }} variant="h6">
              Organization
            </Typography>
            <Typography sx={{ px: 1 }} variant="body2" color="text.secondary">
              {currentOrganization?.name} (Slug: {currentOrganization?.slug})
            </Typography>
            <List>
              <ListItem disablePadding dense>
                <ListItemButton
                  component={Link}
                  to={getLocaleLink("/organization/details")}
                  selected={isActive(getLocaleLink('/organization/details'))}
                >
                  <ListItemIcon>
                    <CorporateFare />
                  </ListItemIcon>
                  <ListItemText primary="Organization Details" />
                </ListItemButton>
              </ListItem>
              {userType === 'admin' && (
                <ListItem disablePadding dense>
                  <ListItemButton
                    component={Link}
                    to={getLocaleLink("/organization/teachers")}
                    selected={isActive(getLocaleLink('/organization/teachers'))}
                  >
                    <ListItemIcon>
                      <SupervisorAccountSharp />
                    </ListItemIcon>
                    <ListItemText primary="Teachers" />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem disablePadding dense>
                <ListItemButton
                  component={Link}
                  to={getLocaleLink("/organization/classes")}
                  selected={isActive(getLocaleLink('/organization/classes'))}
                >
                  <ListItemIcon>
                    <Class />
                  </ListItemIcon>
                  <ListItemText primary="Classes" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding dense>
                <ListItemButton
                  component={Link}
                  to={getLocaleLink("/organization/students")}
                  selected={isActive(getLocaleLink('/organization/students'))}
                >
                  <ListItemIcon>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText primary="Organization Students" />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider sx={{ py: 2 }} />
          </>
        )}

        <Typography sx={{ px: 1, pt: 1 }} variant="h6">
          Account
        </Typography>
        <Typography sx={{ px: 1 }} variant="body2" color="text.secondary">
          {currentUser?.email}
        </Typography>
        <List>
          <ListItem disablePadding dense>
            <ListItemButton
              component={Link}
              to={getLocaleLink("/profile/details")}
              selected={isActive(getLocaleLink('/profile/details'))}
            >
              <ListItemIcon>
                <PersonSharp />
              </ListItemIcon>
              <ListItemText primary="Profile Details" />
            </ListItemButton>
          </ListItem>
          {userType === 'parent' && (
            <ListItem disablePadding dense>
              <ListItemButton
                component={Link}
                to={getLocaleLink("/profile/students")}
                selected={isActive(getLocaleLink('/profile/students'))}
              >
                <ListItemIcon>
                  <Groups />
                </ListItemIcon>
                <ListItemText primary="My Students" />
              </ListItemButton>
            </ListItem>
          )}
          {(userType === 'admin' || userType === 'parent') && (
            <ListItem disablePadding dense>
              <ListItemButton
                component={Link}
                to={getLocaleLink("/profile/billing")}
                selected={isActive(getLocaleLink('/profile/billing'))}
              >
                <ListItemIcon>
                  <AddCard />
                </ListItemIcon>
                <ListItemText primary="Billing" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding dense>
            <ListItemButton onClick={() => logOut.mutate()}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
      <Box component="main" sx={{ width: { md: 'calc(100% - 300px)' }, ml: { md: '300px' } }}>
        <Container maxWidth="md">
          <Outlet />
        </Container>
        <UpgradeToOrgModal/>
      </Box>
    </>
  );
}
