/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChallengeExecution } from './ChallengeExecution';

export type ChallengeData = {
    title: string;
    description: string;
    function_name: string;
    executions: Array<ChallengeExecution>;
    template: string;
    solution: string;
    difficulty: ChallengeData.difficulty;
};

export namespace ChallengeData {

    export enum difficulty {
        BEGINNER = 'beginner',
        ADVANCED = 'advanced',
        EXPERT = 'expert',
    }


}

