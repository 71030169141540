import { Box, Button, Card, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import TipBox from './TipBox';
import { useTranslation } from 'react-i18next';

export default function CodeAdder({
  codeToAdd,
  sx,
  onClick
}: {
  codeToAdd: string;
  sx: SxProps;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <TipBox sx={sx} name="insert-code">
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography>{t('codeEditor.addingText')}</Typography>
        <Box component="div" display="flex">
          <SyntaxHighlighter style={monokaiSublime} PreTag="div" language="python">
            {codeToAdd}
          </SyntaxHighlighter>
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: 1, lineHeight: 1, width: '111px', p: '8px' }}
            onClick={onClick}
          >
            {t('codeEditor.addingButton')}
          </Button>
        </Box>
      </Box>
    </TipBox>
  );
}
