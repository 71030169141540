import { Outlet, useLocation, useParams } from 'react-router-dom';
import { SnackBarProvider } from '../context/SnackBarContext';
import Sidebar from '../components/Sidebar';
import { SidebarProvider } from '../context/SidebarContext';
import Header from '../components/Header';
import NotificationBar from '../components/NotificationBar';
import Feedback from '../components/Feedback/Feedback';
import AssignPurchaseModal from '../components/AssignPurchaseModal';
import { AppProvider } from '../context/AppContext';
import AvatarSelectorGlobal from '../components/AvatarSelectorGlobalModal';
import { useCurrentLocale } from '../hooks/useCurrentLocale';
import { languages } from '../components/LocaleSelect/languages';
import { useEffect } from 'react';

const supportedLocales = languages.map((lang) => lang.code);

export default function Layout() {
  const { data: locale, isLoading } = useCurrentLocale();
  const { lng } = useParams();
  const { pathname } = useLocation();

  const selectedLanguage = languages.find((lang) => lang.name === locale?.language) || languages[0];

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (lng && !supportedLocales.includes(lng)) {
      window.location.href = selectedLanguage
        ? `/${selectedLanguage.code}${pathname}`
        : `/en${pathname}`;
    }
  }, [lng, locale, selectedLanguage.code, selectedLanguage.name]);

  return (
    <AppProvider>
      <SnackBarProvider>
        <SidebarProvider>
          <NotificationBar />
          <Header />
          <Sidebar />
          <Feedback />
          <AssignPurchaseModal />
          <AvatarSelectorGlobal />
          <main
            style={{
              paddingTop: 64,
              background: '#2F3129',
              minHeight: 'calc(100vh - 64px)'
            }}
          >
            <Outlet />
          </main>
          {/* <Footer /> */}
        </SidebarProvider>
      </SnackBarProvider>
    </AppProvider>
  );
}
