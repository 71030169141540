import React, { useEffect } from 'react';
import { Box, DialogActions } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import { LottieRefCurrentProps, useLottie } from 'lottie-react';
import LevelCompleteAnimation from '../resources/animations/level-complete.json';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from './GameUI/PrimaryButton';
import useStudents from './useStudents';
import ContentMarkdown from './GameUI/ContentMarkdown';
import Modal3D from './GameUI/Modal3D';
import RibbonHeader from './GameUI/RibbonHeader';
import { useLocaleContext } from '../context/LocaleContext';

const lottieStyle: { [key: string]: unknown } = {
  pointerEvents: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1400,
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  height: '700px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const CourseCompleteModal = () => {
  const { openModal: open, setOpenModal } = useGenericModal('course-complete');
  const navigate = useNavigate();
  const { currentStudent } = useStudents();
  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  const { View: LottieView, goToAndPlay } = useLottie({
    animationData: LevelCompleteAnimation,
    loop: false,
    autoplay: false,
    style: lottieStyle,
    lottieRef
  });

  const { getLocaleLink } = useLocaleContext();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        goToAndPlay(0);
      }, 10);
    }
  }, [open]);

  const onClose = () => {
    setOpenModal(false);
    navigate(getLocaleLink(`/profile/students/${currentStudent?.id}`));
  };
  const content =
    "You've just completed our \"Coding for Kids - Python Development Course\" and we're beyond proud of you!\n\nYour hard work, curiosity, and creativity have paid off! You now know the basics of coding with Python and are ready to take on new challenges.\n\nAs a special treat, you can download your certificate from your profile page! Share it with friends, family, or teachers to show off your achievement.\n\nRemember, coding is not just about writing code - it's about solving problems, being creative, and learning something new every day!\n\nKeep coding, keep exploring, and most importantly, keep having FUN!\n\nCongratulations again on completing the course! We're honored to have been a part of your coding journey.\n\nBest regards,\n\nThe Coding for Kids Team.";

  return (
    <>
      <Box component="div">{LottieView}</Box>
      <Modal3D open={open} onClose={onClose}>
        <RibbonHeader>WOOHOO! You did it!</RibbonHeader>
        <Box component="div" sx={{ pt: 0, px: 2, pb: 0, mt: 3, display: 'flex', overflow: 'auto' }}>
          <ContentMarkdown content={content} />
        </Box>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <PrimaryButton onClick={onClose} sx={{ width: '300px' }}>
            Continue to Certification
          </PrimaryButton>
        </DialogActions>
      </Modal3D>
    </>
  );
};

export default CourseCompleteModal;
