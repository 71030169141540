import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import { CourseData, LevelData } from '../client';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useLocaleContext } from '../context/LocaleContext';
import { useTranslation } from 'react-i18next';

export default function LevelNavigation({
  courseSlug,
  levelData,
  nextLevel,
  previousLevel
}: {
  courseSlug: string;
  levelData: LevelData;
  nextLevel?: string;
  previousLevel?: string;
}) {
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'));
  const fontSize = desktopView ? 20 : 13;

  const { getLocaleLink } = useLocaleContext();

  const { t } = useTranslation();

  return (
    <Box component="div">
      <Box
        component="div"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 2 }}
      >
        <Button
          variant="outlined"
          size="small"
          href={getLocaleLink(`/play/${courseSlug}/${previousLevel}` || '/')}
          startIcon={<KeyboardDoubleArrowLeft />}
          disabled={previousLevel === undefined}
        >
          {t('levelNavigation.backButton')}
        </Button>
        <Box
          component="div"
          sx={{
            flex: 1,
            display: 'flex',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" sx={{ fontSize: fontSize }}>
            {t('levelNavigation.chapterLabel', { chapter: levelData.chapter_id + 1 })}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: fontSize }}>
            &gt;
          </Typography>
          <Typography variant="h6" sx={{ fontSize: fontSize }} color="text.secondary">
            {t('levelNavigation.levelLabel', { level: levelData.level_id + 1 })}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          size="small"
          href={getLocaleLink(`/play/${courseSlug}/${nextLevel}` || '/')}
          endIcon={<KeyboardDoubleArrowRight />}
          disabled={nextLevel === undefined}
        >
          {t('levelNavigation.nextButton')}
        </Button>
      </Box>
    </Box>
  );
}
