/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChallengeCompletion } from '../models/ChallengeCompletion';
import type { ChallengeData } from '../models/ChallengeData';
import type { ChallengeItem } from '../models/ChallengeItem';
import type { ChallengeStatusUpdate } from '../models/ChallengeStatusUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChallengeService {

    /**
     * Get Challenge
     * Retrieves a challenge by its slug.
     *
     * Args:
     * - slug (str): The slug of the challenge.
     * - lang (str): The language of the challenge.
     *
     * Returns:
     * - ChallengeData: The challenge data.
     * @param slug
     * @param lang
     * @returns ChallengeData Successful Response
     * @throws ApiError
     */
    public static getChallengeApiChallengeLangSlugGet(
        slug: string,
        lang: string,
    ): CancelablePromise<ChallengeData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/challenge/{lang}/{slug}',
            path: {
                'slug': slug,
                'lang': lang,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Challenge
     * Generates a challenge by its slug.
     *
     * Args:
     * - slug (str): The slug of the challenge.
     * - lang (str): The language of the challenge.
     *
     * Returns:
     * - ChallengeData: The challenge data
     * @param slug
     * @param lang
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateChallengeApiChallengeGenerateChallengeLangSlugGet(
        slug: string,
        lang: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/challenge/generate-challenge/{lang}/{slug}',
            path: {
                'slug': slug,
                'lang': lang,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Challenges Route
     * Retrieves a list of challenges.
     *
     * Returns:
     * - List[ChallengeItem]: A list of challenge data.
     * @returns ChallengeItem Successful Response
     * @throws ApiError
     */
    public static getChallengesRouteApiChallengeListGet(): CancelablePromise<Array<ChallengeItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/challenge/list',
        });
    }

    /**
     * Upsert Challenge Completion
     * Updates or inserts a challenge completion record.
     *
     * Args:
     * - challenge_status_update (ChallengeStatusUpdate): The challenge status update data.
     *
     * Returns:
     * - Optional[ChallengeCompletion]: The updated challenge completion data.
     * @param requestBody
     * @returns ChallengeCompletion Successful Response
     * @throws ApiError
     */
    public static upsertChallengeCompletionApiChallengeUpdatePost(
        requestBody: ChallengeStatusUpdate,
    ): CancelablePromise<ChallengeCompletion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/challenge/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
