import AssetLoader from '../@core/AssetLoader';
import SceneManager from '../@core/SceneManager';
import Scene from '../@core/Scene';
import GameScene from '../scenes/GameScene';
import Game from '../@core/Game';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import CodeEditor from './CodeEditor';
import { useQueryClient } from 'react-query';
import { mapHelper } from '../@core/utils/mapHelper';
import spriteData from '../spriteData';
import { CourseData, LevelCompletionStatus, LevelData } from '../client';
import LevelCompleteModal from './LevelCompleteModal';
import GuideModal from './GuideModal';
import ErrorModal from './ErrorModal';
import { useGenericModal } from '../context/GenericModalContext';
import HintModal from './HintModal';
import TipModal from './TipModal';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import MobileFooter from './MobileFooter';
import LevelNavigation from './LevelNavigation';
import GameTopBar from './GameTopBar';
import ChooseStudentModal from './ChooseStudentModal';
import { useCode } from '../context/CodeContext';
import useLevels from './useLevels';
import CourseCompleteModal from './CourseCompleteModal';
import GoToSignUpModal from './GoToSignUpModal';
import FinalLevelCompleteModal from './FinalLevelCompleteModal';
import useLevelCompletion from './useLevelCompletion';
import { useCurrentUser } from '../hooks/useCurrentUser';
import ChapterProgressBarStatic from './ChapterProgressBarStatic';
import MarkdownSection from './MarkdownSection';

function getNavigationSlugs(
  courseData: CourseData,
  levelData: LevelData
): [string | undefined, string | undefined] {
  const chapterData = courseData.chapters[levelData.chapter_id];

  let nextLevelSlug = undefined;
  let previousLevelSlug = undefined;

  if (levelData.level_id < chapterData.level_data.length - 1) {
    nextLevelSlug = chapterData.level_data[levelData.level_id + 1].slug;
  } else {
    if (levelData.chapter_id < courseData.chapters.length - 1) {
      nextLevelSlug = courseData.chapters[levelData.chapter_id + 1].level_data[0].slug;
    }
  }

  if (levelData.level_id > 0) {
    previousLevelSlug = chapterData.level_data[levelData.level_id - 1].slug;
  } else {
    if (levelData.chapter_id > 0) {
      previousLevelSlug =
        courseData.chapters[levelData.chapter_id - 1].level_data[
          courseData.chapters[levelData.chapter_id - 1].level_data.length - 1
        ].slug;
    }
  }

  return [nextLevelSlug, previousLevelSlug];
}

export default function GameScreen() {
  const { levelId, courseId, levelData, levelDataLoading, courseData, courseDataLoading } =
    useLevels();
  const { updateLevelCompletion } = useLevelCompletion();
  const { currentUser, isCurrentUserLoading } = useCurrentUser();
  const [tilesetsFirstgid, setTilesetsFirstgid] = useState<{ [key: number]: any }>();
  const [isCourseCompleteInitiated, setIsCourseCompleteInitiated] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    setContent: setGuide,
    setOpenModal: setOpenGuideModal,
    content: guideContent
  } = useGenericModal('guide');
  const { setContent: setHint } = useGenericModal('hint');
  const { setOpenModal: setOpenCourseCompleteModal } = useGenericModal('course-complete');
  const { setOpenModal: setOpenFinalLevelCompleteModal } = useGenericModal('final-level-complete');
  const { setOpenModal: setGoToSignUpModal } = useGenericModal('go-to-sign-up-modal');

  const { setApiLevelData } = useApiLevelData();

  const signUpModalLevels = ['intro-level5', 'intro-level6', 'intro-level7'];
  const incompleteLevels = ['paste-incomplete-levels-slug-here'];

  useEffect(() => {
    if (levelId && incompleteLevels.includes(levelId)) {
      setGuide(
        'This level is not ready yet. We are terribly sorry for the inconvenience. Please check back next week. For more information, please contact support@codingforkids.io for more information.'
      );
      setOpenGuideModal(true);
    }
  }, []);

  useEffect(() => {
    if (!levelData || isCurrentUserLoading) return;
    if (levelData.slug && signUpModalLevels.includes(levelData.slug) && !currentUser) {
      setGoToSignUpModal(true);
    }
  }, [levelData, currentUser]);

  const [mobileToggle, setMobileToggle] = useState<'code' | 'game'>('code');
  const theme = useTheme();
  const desktopView = useMediaQuery(theme.breakpoints.up('sm'));

  const showCode = mobileToggle === 'code' || desktopView;
  const showGame = mobileToggle === 'game' || desktopView;

  const { code } = useCode();

  const onLevelComplete = async () => {
    await updateLevelCompletion.mutateAsync({
      last_status: LevelCompletionStatus.COMPLETE,
      level_slug: levelId
    });
    queryClient.invalidateQueries(`courseData-${currentUser?.user_id}-${currentUser?.student_id}`);

    if (!courseData || !levelData) return;

    const isLastChapter = levelData.chapter_id === courseData.chapters.length - 1;
    const isLastLevel =
      levelData.level_id === courseData.chapters[levelData.chapter_id].level_data.length - 1;

    if (isLastChapter && isLastLevel) {
      const courseCompleted = courseData?.chapters.every((chapter) =>
        chapter.level_data.every((level) => level.completed || level.slug === levelId)
      );
      if (courseCompleted) {
        setOpenCourseCompleteModal(true);
      } else {
        setOpenFinalLevelCompleteModal(true);
      }
    } else {
      setIsCourseCompleteInitiated(true);
    }
  };

  useEffect(() => {
    if (!isCourseCompleteInitiated) return;
    const courseCompleted = courseData?.chapters.every((chapter) =>
      chapter.level_data.every((level) => level.completed)
    );
    if (courseCompleted) {
      setOpenCourseCompleteModal(true);
    }
  }, [courseData, isCourseCompleteInitiated]);

  const onCodeExecution = () => {
    updateLevelCompletion.mutateAsync({
      last_status: LevelCompletionStatus.IN_PROGRESS,
      last_code_executed: code,
      level_slug: levelId
    });
  };

  useEffect(() => {
    if (levelData) {
      mapHelper.getTilesetMapperDynamicJsonImports(levelData.map.tilesets).then((tilesets) => {
        setTilesetsFirstgid(tilesets);
      });
      if (levelData.guide) {
        if (!(levelId && incompleteLevels.includes(levelId))) {
          setGuide(levelData.guide, levelData.guideImage);
        }
      }
      if (levelData.hint) {
        setHint(levelData.hint);
      }
      setApiLevelData(levelData);
    }
  }, [levelData]);

  if (levelDataLoading || courseDataLoading || tilesetsFirstgid === undefined) {
    return (
      <Box
        component="div"
        sx={{
          display: 'flex',
          height: 'calc(100vh - 64px)',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          marginTop: 4
        }}
      >
        <CircularProgress /> <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (courseData === undefined || levelData === undefined) {
    return <Typography>No data</Typography>;
  }

  const allAssetUrls = mapHelper.getAllAssetUrls(levelData.map, tilesetsFirstgid);

  const urls = [...allAssetUrls, ...Object.values(spriteData).map((data) => data.src)].reduce<
    string[]
  >((acc, val) => acc.concat(val), []);

  const [nextLevelSlug, previousLevelSlug] = getNavigationSlugs(courseData, levelData);

  return (
    <Box
      component="div"
      sx={{ flex: 1, width: '100%', height: 'calc(100vh - 64px)', display: 'flex' }}
    >
      <Box
        component="div"
        sx={{
          flexDirection: 'column',
          overflowY: 'scroll',
          height: 'calc(100vh - 64px)',
          width: 640,
          maxWidth: '100%',
          paddingLeft: 3,
          paddingRight: 3,
          display: showCode ? 'flex' : 'none'
        }}
      >
        {guideContent && <GuideModal />}
        <HintModal />
        <TipModal />
        <ErrorModal />
        <GoToSignUpModal />
        <LevelCompleteModal
          nextLevel={nextLevelSlug}
          currentLevel={levelData.slug}
          codeTemplate={levelData.codeTemplate}
        />
        <CourseCompleteModal />
        <FinalLevelCompleteModal courseData={courseData} />
        <ChooseStudentModal />
        {levelId === 'creative' && (
          <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
            Creative Mode
          </Typography>
        )}

        <Box component="div" sx={{ display: levelId === 'creative' ? 'none' : 'block' }}>
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="h6" align="center">
              {courseData.name}
            </Typography>
          </Box>
          <LevelNavigation
            courseSlug={courseId}
            levelData={levelData}
            nextLevel={nextLevelSlug}
            previousLevel={previousLevelSlug}
          />
          <ChapterProgressBarStatic />
          <Divider sx={{ mt: 2 }} />
          <Typography variant="h5" gutterBottom dir="auto">
            {levelData.chapter_name}
          </Typography>
          <Typography variant="h6" color="text.secondary" gutterBottom dir="auto">
            {levelData.name}
          </Typography>

          <Box component="div" className="level-data-markdown" dir="auto">
            <MarkdownSection markdown={levelData.tutorial} />
          </Box>
        </Box>
        <CodeEditor
          codeTemplate={levelData.codeTemplate}
          setMobileToggle={setMobileToggle}
          courseData={courseData}
          onCodeExecution={onCodeExecution}
          maxLinesOfCode={levelData.maxLinesOfCode}
          chapterId={levelData.chapter_id}
        />
      </Box>
      <Box
        component="div"
        sx={{
          flex: 1,
          height: '100%',
          overflow: 'none',
          display: showGame ? 'flex' : 'none',
          alignItems: 'stretch',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <GameTopBar />
        <Game
          cameraZoom={levelData.zoom}
          levelData={levelData}
          showCode={showCode}
          onLevelComplete={onLevelComplete}
        >
          <AssetLoader urls={urls} placeholder="Loading assets ...">
            <SceneManager defaultScene="office" levelData={levelData} courseData={courseData}>
              <Scene id="office">
                <GameScene levelData={levelData} />
              </Scene>
            </SceneManager>
          </AssetLoader>
        </Game>
      </Box>

      <Box component="div" sx={{ display: desktopView ? 'none' : 'block' }}>
        <MobileFooter mobileToggle={mobileToggle} setMobileToggle={setMobileToggle} />
      </Box>
    </Box>
  );
}
