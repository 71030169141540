import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Modal,
  Paper,
  Stack,
  TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';

import useStudents from './useStudents';
import { Loader } from './Loader';
import { useAppContext } from '../context/AppContext';
import { UserContext } from '../pages/App';
import { StudentListItem } from '../client';
import { useLocaleContext } from '../context/LocaleContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column'
};

const contentStyle = {
  flexGrow: 1,
  overflowY: 'auto',
  maxHeight: 'calc(80vh - 64px)'
};

export function StudentCard({
  student,
  selectedStudent,
  onClick
}: {
  student: StudentListItem;
  selectedStudent?: string | undefined;
  onClick: () => void;
}) {
  return (
    <Card
      key={student.id}
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: selectedStudent && student.id === selectedStudent ? 'primary.main' : '#666',
        borderStyle: 'solid',
        borderWidth: 1,
        minHeight: 80,
        '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(255,255,255,0.1)' }
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 64, px: 1 }}
        image={`/assets/characters/portraits/${student.character}.png`}
        alt={student.username}
      />
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Typography variant="h5" sx={{ pt: 1 }}>
          {student.username}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default function ChooseStudentModal() {
  const { openChooseStudent, setOpenChooseStudent } = useAppContext();
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const currentUser = useContext(UserContext);
  const { students, chooseStudent, isStudentsLoading } = useStudents();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const { getLocaleLink } = useLocaleContext();

  if (!currentUser) return null;

  const handleConfirm = async () => {
    if (!selectedStudent) return;
    await chooseStudent.mutateAsync(selectedStudent);
    onClose();
  };

  const onClose = () => {
    setOpenChooseStudent(false);
  };

  const newStudentLink = currentUser.organization_id
    ? getLocaleLink(`/organization/students/add`)
    : getLocaleLink(`/profile/students/add`);

  return (
    <Modal
      open={openChooseStudent}
      onClose={onClose}
      aria-labelledby="select-avatar-modal"
      aria-describedby="modal-modal-description"
    >
      {!students || isStudentsLoading ? (
        <Loader />
      ) : (
        <Paper sx={style}>
          <Typography variant="h5" sx={{ textAlign: 'center', py: 2 }}>
            Who are you?
          </Typography>
          <TextField
            size="medium"
            type="search"
            placeholder="Search for a student..."
            sx={{ my: 2 }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <Stack gap={2} sx={contentStyle}>
            {students
              .filter((student) => student.username.toLowerCase().includes(search.toLowerCase()))
              .map((student) => (
                <StudentCard
                  key={student.id}
                  student={student}
                  selectedStudent={selectedStudent}
                  onClick={() => setSelectedStudent(student.id)}
                />
              ))}
            <StudentCard
              student={{
                full_name: 'New Student',
                username: 'New Student',
                character: 'Rabbit',
                levels_completed: 0
              }}
              onClick={() => navigate(newStudentLink)}
            />
          </Stack>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={!selectedStudent}
              sx={{ mt: 2 }}
            >
              Play
            </Button>
          </Box>
        </Paper>
      )}
    </Modal>
  );
}
