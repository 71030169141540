import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

import { Link, Stack } from '@mui/material';
import {
  AddCard,
  AutoStories,
  CorporateFare,
  EmojiEmotions,
  Groups,
  HistoryEdu,
  Login,
  Person,
  SaveAlt,
  School,
  ShoppingCart,
  SupervisorAccountSharp,
  SwapHoriz,
  WorkspacePremium
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import { AuthService, UserType } from '../client';
import { useAppContext } from '../context/AppContext';
import { UserContext, UserTypeMap } from '../pages/App';
import StudentAvatar from './StudentAvatar';
import { useGenericModal } from '../context/GenericModalContext';
import { IconBrandDiscord } from '@tabler/icons-react';
import { LocaleSelect } from './LocaleSelect/LocaleSelect';
import { useLocaleContext } from '../context/LocaleContext';

function LogInTooltip() {
  return (
    <Stack gap={2} sx={{ p: 2, alignItems: 'center' }}>
      <SaveAlt fontSize="large" />
      <Typography variant="h6" align="center">
        Log in to store progress and code
      </Typography>
    </Stack>
  );
}

export default function AccountMenu() {
  const { setOpenChooseStudent } = useAppContext();

  const { getLocaleLink } = useLocaleContext();

  const { setOpenModal: setOpenAvatarSelectorGlobalModal } = useGenericModal(
    'avatar-selector-global-modal'
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const currentUser = useContext(UserContext);
  const currentStudent = currentUser?.student_details;
  const [logInTooltipOpen, setLogInTooltipOpen] = useState(false);

  const logOut = useMutation(AuthService.logoutApiAuthLogoutGet, {
    onSuccess: () => {
      queryClient.invalidateQueries('currentUser');
      document.location.href = getLocaleLink('/');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('login-tooltip-shown')) {
      return;
    }
    const timerId = setTimeout(() => {
      localStorage.setItem('login-tooltip-shown', 'true');
      setLogInTooltipOpen(true);
    }, 30000);

    return () => clearTimeout(timerId);
  }, []);

  const userType = UserTypeMap[currentUser?.user_type || UserType._0];

  const studentsLink = currentUser?.organization_id
    ? getLocaleLink(`/organization/students`)
    : getLocaleLink(`/profile/students`);

  return (
    <>
      <Box
        component="div"
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', gap: 1 }}
      >
        <LocaleSelect />
        <IconButton
          component={Link}
          target="_blank"
          href="https://discord.gg/dFB8zMrSmB"
          size="small"
        >
          <IconBrandDiscord />
        </IconButton>

        <Button
          variant="outlined"
          component={Link}
          target="_blank"
          href="https://coding-for-kids.upvoty.com/b/feature-requests/"
          size="small"
          width={180}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Vote for features
        </Button>
        {currentUser !== undefined ? (
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {currentStudent ? (
                <StudentAvatar student={currentStudent} size={32} />
              ) : (
                <Avatar alt={currentUser.email}>
                  {currentUser.email?.substring(0, 1)?.toUpperCase()}
                </Avatar>
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            title={<LogInTooltip />}
            onOpen={() => setLogInTooltipOpen(true)}
            onClose={() => setLogInTooltipOpen(false)}
          >
            <Button
              size="small"
              variant="contained"
              startIcon={<Login />}
              sx={{ width: 100 }}
              href={getLocaleLink('/login')}
            >
              Log In
            </Button>
          </Tooltip>
        )}
      </Box>
      {currentUser?.is_student ? (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {currentStudent && (
            <MenuItem
              sx={{ minWidth: 220 }}
              component={NavLink}
              to={getLocaleLink(`/profile/students/${currentStudent.id}`)}
            >
              <StudentAvatar student={currentStudent} size={32} variant="body1" />
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={() => setOpenAvatarSelectorGlobalModal(true)}>
            <ListItemIcon>
              <EmojiEmotions fontSize="small" />
            </ListItemIcon>
            Choose Avatar
          </MenuItem>
          <MenuItem component={NavLink} to={getLocaleLink(`/courses`)}>
            <ListItemIcon>
              <AutoStories fontSize="small" />
            </ListItemIcon>
            Courses
          </MenuItem>
          <MenuItem component={NavLink} to={getLocaleLink(`/certificates/${currentStudent?.id}`)}>
            <ListItemIcon>
              <HistoryEdu fontSize="small" />
            </ListItemIcon>
            My Certificates
          </MenuItem>
          <MenuItem component={NavLink} to={getLocaleLink(`/badges/${currentStudent?.id}`)}>
            <ListItemIcon>
              <WorkspacePremium fontSize="small" />
            </ListItemIcon>
            My Badges
          </MenuItem>
          <MenuItem onClick={() => logOut.mutate()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem sx={{ minWidth: 220 }}>{currentUser?.email}</MenuItem>
          {currentStudent && !currentUser?.organization_id && (
            <MenuItem
              sx={{ minWidth: 220 }}
              component={NavLink}
              to={`/profile/students/${currentStudent.id}`}
            >
              <StudentAvatar student={currentStudent} size={32} variant="body1" />
            </MenuItem>
          )}
          {currentStudent && currentUser?.organization_id && (
            <MenuItem
              sx={{ minWidth: 220 }}
              component={NavLink}
              to={getLocaleLink(`/organization/students/${currentStudent.id}`)}
            >
              <StudentAvatar
                student={currentStudent}
                size={32}
                variant="body1"
                classDetails={currentUser?.class_details}
              />
            </MenuItem>
          )}
          <MenuItem onClick={() => setOpenChooseStudent(true)}>
            <ListItemIcon>
              <SwapHoriz fontSize="small" />
            </ListItemIcon>
            Choose Student
          </MenuItem>
          {currentStudent && (
            <MenuItem onClick={() => setOpenAvatarSelectorGlobalModal(true)}>
              <ListItemIcon>
                <EmojiEmotions fontSize="small" />
              </ListItemIcon>
              Choose Avatar
            </MenuItem>
          )}
          {currentStudent && (
            <MenuItem component={NavLink} to={getLocaleLink(`/certificates/${currentStudent?.id}`)}>
              <ListItemIcon>
                <HistoryEdu fontSize="small" />
              </ListItemIcon>
              My Certificates
            </MenuItem>
          )}
          {currentStudent && (
            <MenuItem component={NavLink} to={getLocaleLink(`/badges/${currentStudent?.id}`)}>
              <ListItemIcon>
                <WorkspacePremium fontSize="small" />
              </ListItemIcon>
              My Badges
            </MenuItem>
          )}
          <MenuItem component={NavLink} to={getLocaleLink('/profile/details')}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
          {userType === 'admin' && (
            <MenuItem component={NavLink} to={getLocaleLink('/organization/details')}>
              <ListItemIcon>
                <CorporateFare fontSize="small" />
              </ListItemIcon>
              Organization Details
            </MenuItem>
          )}
          {userType === 'admin' && (
            <MenuItem component={NavLink} to={getLocaleLink('/organization/teachers')}>
              <ListItemIcon>
                <SupervisorAccountSharp fontSize="small" />
              </ListItemIcon>
              Teachers
            </MenuItem>
          )}
          <MenuItem component={NavLink} to={studentsLink}>
            <ListItemIcon>
              <Groups fontSize="small" />
            </ListItemIcon>
            Students
          </MenuItem>
          {(userType === 'admin' || userType === 'parent') && (
            <MenuItem component={NavLink} to={getLocaleLink('/profile/billing')}>
              <ListItemIcon>
                <AddCard fontSize="small" />
              </ListItemIcon>
              Billing
            </MenuItem>
          )}
          {/*<MenuItem onClick={handleClose}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <School fontSize="small" />*/}
          {/*  </ListItemIcon>*/}
          {/*  Courses*/}
          {/*</MenuItem>*/}
          {/*<MenuItem onClick={handleClose}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <BarChart fontSize="small" />*/}
          {/*  </ListItemIcon>*/}
          {/*  Statistics*/}
          {/*</MenuItem>*/}
          <Divider />
          {/*<MenuItem onClick={openSubscriptionCheckout}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <AddCard fontSize="small" />*/}
          {/*  </ListItemIcon>*/}
          {/*  Subscribe*/}
          {/*</MenuItem>*/}
          <MenuItem component={Link} href={getLocaleLink('/purchase')}>
            <ListItemIcon>
              <ShoppingCart fontSize="small" />
            </ListItemIcon>
            Buy Course
          </MenuItem>
          <MenuItem component={Link} href={getLocaleLink('/schools')}>
            <ListItemIcon>
              <School fontSize="small" />
            </ListItemIcon>
            For Institutions
          </MenuItem>
          <MenuItem onClick={() => logOut.mutate()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
