import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  enJSON,
  esJSON,
  frJSON,
  deJSON,
  zhJSON,
  heJSON,
  arJSON,
  ukJSON,
  ptJSON,
  hiJSON,
  jaJSON,
  itJSON,
  koJSON,
  nlJSON,
  svJSON,
  plJSON,
  ruJSON,
  daJSON,
  fiJSON,
  noJSON,
  trJSON,
  elJSON,
  thJSON,
  csJSON,
  huJSON,
  roJSON,
  bgJSON,
  idJSON,
  msJSON,
  viJSON
} from './locales';

export const initLocale = (defaultLocale: string) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { ...enJSON },
      es: { ...esJSON },
      fr: { ...frJSON },
      de: { ...deJSON },
      zh: { ...zhJSON },
      he: { ...heJSON },
      ar: { ...arJSON },
      uk: { ...ukJSON },
      pt: { ...ptJSON },
      hi: { ...hiJSON },
      ja: { ...jaJSON },
      it: { ...itJSON },
      ko: { ...koJSON },
      nl: { ...nlJSON },
      sv: { ...svJSON },
      pl: { ...plJSON },
      ru: { ...ruJSON },
      da: { ...daJSON },
      fi: { ...fiJSON },
      no: { ...noJSON },
      tr: { ...trJSON },
      el: { ...elJSON },
      th: { ...thJSON },
      cs: { ...csJSON },
      hu: { ...huJSON },
      ro: { ...roJSON },
      bg: { ...bgJSON },
      id: { ...idJSON },
      ms: { ...msJSON },
      vi: { ...viJSON }
    },
    lng: defaultLocale
  });
};
