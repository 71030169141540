import React from 'react';
import { Box, Tooltip, Typography, keyframes } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import useLevels from './useLevels';
import { LevelDataSummary } from '../client';
import { useGenericModal } from '../context/GenericModalContext';
import { useLocaleContext } from '../context/LocaleContext';

const rotateScaleUpVer = keyframes`
  0% {
    transform: scale(1) rotateY(0);
    background-color: #F1E5C6;
  }
  50% {
    transform: scale(1.3) rotateY(180deg);
    background-color: #D9C89A;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1) rotateY(360deg);
    background-color: #74BE1A;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const starAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

interface ProgressTooltipProps {
  level: LevelDataSummary;
  levelNumber: number;
}

const ProgressTooltip = ({ level, levelNumber }: ProgressTooltipProps) => (
  <div>
    <Typography variant="body2">
      Level {levelNumber}: {level.name}
    </Typography>
  </div>
);

const ChapterProgressBarAnimated: React.FC = () => {
  const { setOpenModal: setOpenLevelCompleteModal } = useGenericModal('level-complete');
  const { courseData, levelData } = useLevels();
  const currentLevel = levelData?.slug;
  const currentChapter = courseData?.chapters.find((chapter) =>
    chapter.level_data.some((level) => level.slug === currentLevel)
  );

  const { getLocaleLink } = useLocaleContext();

  const levels = currentChapter?.level_data || [];

  const handleBlockClick = (levelSlug: string) => {
    setOpenLevelCompleteModal(false);
    window.location.href = getLocaleLink(`/play/${courseData?.id}/${levelSlug}`);
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {levels.map((level, index) => (
        <Tooltip
          title={<ProgressTooltip level={level} levelNumber={index + 1} />}
          arrow
          key={index}
        >
          <Box
            component="div"
            sx={{
              position: 'relative',
              width: '26px',
              height: '26px',
              border: '1px solid #AF8B68',
              backgroundColor: level.completed
                ? level.slug === currentLevel
                  ? '#F1E5C6'
                  : '#74BE1A'
                : '#F1E5C6',
              animation:
                level.slug === currentLevel ? `${rotateScaleUpVer} 0.85s linear 1.6s both` : 'none',
              borderRadius: '4px',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
              transition: 'box-shadow 0.125s ease-in-out, transform 0.125s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1) !important'
              }
            }}
            onClick={() => handleBlockClick(level.slug)}
          >
            {level.completed && (
              <StarIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '16px',
                  color: '#F1E5C6'
                }}
              />
            )}
            {level.slug === currentLevel && (
              <StarIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '16px',
                  color: '#F1E5C6',
                  opacity: 0,
                  animation: `${starAnimation} 0.5s 2s forwards`
                }}
              />
            )}
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

export default ChapterProgressBarAnimated;
