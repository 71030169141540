import { Link } from 'react-router-dom';
import { Box, IconButton, Tooltip, Paper } from '@mui/material';
import { Edit, Delete, Share } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import { useSnackBarContext } from '../../../context/SnackBarContext';
import useOrganizationTeachers from '../../../hooks/useOrganizationTeachers';
import { AuthService, User } from '../../../client';
import { Loader } from '../../Loader';
import { useMutation } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../../pages/App';
import { DataGrid, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { useLocaleContext } from '../../../context/LocaleContext';

export const TeacherUsersList = () => {
  const confirm = useConfirm();

  const currentUser = useContext(UserContext);
  const { teachers, isTeachersLoading, deleteTeacherUserMutation } = useOrganizationTeachers();
  const { createSnackBar } = useSnackBarContext();

  const { getLocaleLink } = useLocaleContext();

  const generateLinkMutation = useMutation(
    AuthService.generateTeacherSignupLinkApiAuthGenerateUserSignupLinkPost,
    {
      onSuccess: (link) => {
        navigator.clipboard.writeText(link);
        createSnackBar({
          content: 'Sign-up link copied to clipboard',
          severity: 'success',
          autoHide: true
        });
      }
    }
  );

  if (isTeachersLoading) return <Loader />;

  if (!teachers || teachers.length === 0) return <div>No teachers</div>;

  const handleTeacherRemove = (teacher: User) => {
    confirm({
      description: `Are you sure you want to remove ${teacher.email}?`,
      title: 'Remove teacher',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        if (!teacher.id) return;
        try {
          deleteTeacherUserMutation.mutateAsync(teacher.id);
          createSnackBar({
            content: 'Teacher has been deleted successfully',
            severity: 'success',
            autoHide: true
          });
        } catch (error) {
          createSnackBar({
            content: 'Error deleting teacher',
            severity: 'error',
            autoHide: true
          });
        }
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  const generateAndCopySignupLink = async (email: string) => {
    try {
      await generateLinkMutation.mutateAsync(email);
    } catch (error) {
      createSnackBar({
        content: 'Error generating sign-up link',
        severity: 'error',
        autoHide: true
      });
    }
  };

  const columns = [
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'full_name', headerName: 'Full Name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const teacher = params.row;
        return (
          <Box component="div">
            {currentUser?.user_id !== teacher.id && (
              <Box component="div">
                {!teacher.verified && (
                  <Tooltip title="Generate a sign-up link">
                    <IconButton
                      aria-label="copy"
                      onClick={async () => await generateAndCopySignupLink(teacher.email)}
                    >
                      <Share sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Edit teacher">
                  <Link to={getLocaleLink(`/organization/teachers/${teacher.id}/edit`)}>
                    <IconButton aria-label="edit">
                      <Edit sx={{ width: 18, height: 18 }} />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Delete teacher">
                  <IconButton aria-label="delete" onClick={() => handleTeacherRemove(teacher)}>
                    <Delete sx={{ width: 18, height: 18 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        );
      }
    }
  ];

  return (
    <Box sx={{ height: 500, width: '100%', mt: 2, mb: 2 }} component={Paper}>
      <DataGrid rows={teachers} columns={columns} slots={{ toolbar: GridToolbar }} />
    </Box>
  );
};
