import { Button, TextField, Typography, Container, Box, Paper } from '@mui/material';
import { useMutation } from 'react-query';
import { AuthService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from '../components/Profile/resetPasswordSchema';
import { useLocaleContext } from '../context/LocaleContext';

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { createSnackBar } = useSnackBarContext();

  const { getLocaleLink } = useLocaleContext();

  const token = searchParams.get('token') || '';

  const resetPasswordMutation = useMutation<
    boolean,
    Error,
    { token: string; new_password: string }
  >(
    ({ token, new_password }) =>
      AuthService.resetPasswordApiAuthResetPasswordPost(token, new_password),
    {
      onSuccess: () => {
        createSnackBar({
          content: 'Password reset successfully.',
          autoHide: true,
          severity: 'success'
        });
        navigate(getLocaleLink('/login'));
      }
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange'
  });

  const onSubmit = async ({ new_password }: { new_password: string }) => {
    try {
      await resetPasswordMutation.mutateAsync({ token, new_password });
    } catch (e) {
      createSnackBar({
        content: 'Error resetting password.',
        autoHide: true,
        severity: 'error'
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Typography component="h1" variant="h5" sx={{ display: 'flex', gap: 1 }}>
          Reset Password
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="new_password"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                type="password"
                error={!!errors.new_password}
                helperText={errors.new_password?.message}
                fullWidth
                label="New password"
                margin="normal"
                value={value}
                onChange={onChange}
                required
              />
            )}
          />
          <Controller
            name="confirm_password"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="password"
                label="Confirm new password"
                fullWidth
                margin="normal"
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                onChange={onChange}
                required
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            disabled={resetPasswordMutation.isLoading || isSubmitting}
          >
            Confirm
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
