import { useMutation } from 'react-query';
import { ChallengeService, ChallengeStatusUpdate } from '../client';

export default function useChallengeCompletion() {
  const updateChallengeCompletion = useMutation(
    (updatedChallengeStatus: ChallengeStatusUpdate) => {
      return ChallengeService.upsertChallengeCompletionApiChallengeUpdatePost(
        updatedChallengeStatus
      );
    },
  );
  return { updateChallengeCompletion };
}
