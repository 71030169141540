import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import useQuizData from './useQuizData';
import useLevels from '../../components/useLevels';
import { useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useLocaleContext } from '../../context/LocaleContext';

export default function QuizPage() {
  const { courseId, chapterId, questionId } = useParams();
  const { answerData, isAnswerLoading, chapterQuestions, chapterComplete, currentChapter } =
    useQuizData();
  const { courseData } = useLevels();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { getLocaleLink } = useLocaleContext();

  useEffect(() => {
    if (!chapterComplete) {
      return;
    }
    (async () => {
      if (!currentChapter) {
        return;
      }
      try {
        await confirm({
          title: 'Quiz Complete',
          content: 'Would you like to continue?'
        });
      } catch (e) {
        return;
      }

      const nextChapter = courseData?.chapters[courseData?.chapters.indexOf(currentChapter) + 1];

      navigate(getLocaleLink(`/play/${courseId}/${nextChapter?.level_data[0].slug}`));
    })();
  }, [chapterComplete]);

  if (!chapterQuestions || isAnswerLoading || !answerData || !courseData?.chapters) {
    return <Loader />;
  }
  if (!currentChapter || !chapterQuestions) {
    return <Box component="div">Error getting chapter data</Box>;
  }

  if (chapterQuestions.questions.length === 0) {
    return <Box component="div">No questions</Box>;
  }

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid
          item
          xs={3}
          sx={{
            height: 'calc(100vh - 50px)',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            backgroundColor: 'background.paper'
          }}
        >
          <Typography variant="h5" sx={{ p: 1 }}>
            {currentChapter.name}
          </Typography>
          <Divider />
          <List sx={{ height: 0, flex: 1 }}>
            {chapterQuestions.questions.map((question) => (
              <ListItemButton
                key={question.id}
                selected={Number(questionId) === question.id}
                onClick={() => navigate(getLocaleLink(`/quiz/${courseId}/${chapterId}/question/${question.id}`))}
              >
                <ListItemIcon>
                  {answerData.find((answer) => answer.question_id === question.id) ? (
                    <CheckBox />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </ListItemIcon>
                <ListItemText>Question {question.id}</ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}
