import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@react-three/drei';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../App';
import useQuizData from './useQuizData';
import useLevels from '../../components/useLevels';
import { QuizQuestion } from '../../client';
import { useLocaleContext } from '../../context/LocaleContext';

function seededRandom(seed: string): () => number {
  let h = 0;
  for (let i = 0; i < seed.length; i++) {
    h = (Math.imul(31, h) + seed.charCodeAt(i)) | 0;
  }
  return function () {
    h = Math.imul(31, h) + 1;
    const t = Math.sin(h) * 10000;
    return t - Math.floor(t);
  };
}

function shuffleArray<T>(array: T[], seed: string): T[] {
  const random = seededRandom(seed);
  const result = array.slice();
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}

function CodeSection({
  selected,
  children,
  onClick
}: {
  selected: boolean;
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <Box
      component="div"
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: 'background.default' }
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontFamily: 'monospace',
          p: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: selected ? 'primary.main' : 'divider',
          borderRadius: 1,
          fontSize: 14
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}

export default function Question() {
  const { courseId, chapterId, questionId } = useParams();
  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(undefined);
  const { chapterQuestions, answerData, isAnswerLoading, answerMutation } = useQuizData();
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();
  const { courseData } = useLevels();

  const { getLocaleLink } = useLocaleContext();

  const questionData = chapterQuestions?.questions.find((x) => x.id === Number(questionId));
  const currentChapter = courseData?.chapters.find((x) => x.id === chapterId);

  useEffect(() => {
    if (!answerData) {
      return;
    }
    const currentAnswer = answerData.find((answer) => answer.question_id === Number(questionId));
    if (!currentAnswer) {
      setSelectedAnswer(undefined);
      return;
    }
    if (Number(questionId) === currentAnswer.question_id) {
      setSelectedAnswer(currentAnswer.answer_id);
    }
  }, [questionId, answerData]);

  if (!questionData || isAnswerLoading || !answerData) {
    return <Loader />;
  }

  const saveAnswer = async () => {
    if (
      !currentUser?.student_id ||
      !courseId ||
      !chapterId ||
      !questionId ||
      !selectedAnswer ||
      !chapterQuestions
    ) {
      return;
    }
    await answerMutation.mutateAsync({
      student_id: currentUser?.student_id,
      course_id: courseId,
      chapter_id: chapterId,
      question_id: Number(questionId),
      answer_id: selectedAnswer
    });
    const nextQuestion = Number(questionId) + 1;
    if (nextQuestion <= chapterQuestions?.questions.length) {
      navigate(getLocaleLink(`/quiz/${courseId}/${chapterId}/question/${nextQuestion}`));
    }
  };

  if (!currentChapter) {
    return <Box component="div">Error getting chapter data</Box>;
  }

  const answers = [1, 2, 3, 4];
  const shuffledAnswers = shuffleArray(
    answers,
    `${courseId}-${chapterId}-${questionId}-${currentUser?.student_id}`
  );

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="sm">
        <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            component="div"
            sx={{
              p: 2,
              pb: 0,
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <Typography variant="h6" color="primary">
              {currentChapter.name} - Question {questionId}
            </Typography>
            <Typography>{questionData.text}</Typography>
          </Box>
          <Divider />
          <Box component="div" sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {shuffledAnswers.map((answerId) => (
              <CodeSection
                key={answerId}
                selected={selectedAnswer === answerId}
                onClick={() => setSelectedAnswer(answerId)}
              >
                {questionData[`answer_${answerId}` as keyof QuizQuestion]}
              </CodeSection>
            ))}
          </Box>
          <Divider />
          <Box component="div" sx={{ display: 'flex', gap: 2, p: 2, pt: 0 }} onClick={saveAnswer}>
            <Button color="primary" variant="contained" disabled={selectedAnswer === undefined}>
              Submit
            </Button>
            <Button color="primary" variant="outlined" onClick={() => setSelectedAnswer(undefined)}>
              Reset
            </Button>
          </Box>
        </Card>
      </Container>
    </Box>
  );
}
