import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Container, Paper, Typography, Box, Chip, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useChallenges } from '../hooks/useChallenges';
import { ChallengeItem } from '../client';
import { useLocaleContext } from '../context/LocaleContext';
import { PlayArrow, Star } from '@mui/icons-material';
import { Loader } from '../components/Loader';

const difficultyColors: Record<ChallengeItem.difficulty, 'success' | 'warning' | 'error'> = {
  [ChallengeItem.difficulty.BEGINNER]: 'success',
  [ChallengeItem.difficulty.ADVANCED]: 'warning',
  [ChallengeItem.difficulty.EXPERT]: 'error'
};

export default function Challenges() {
  const { getLocaleLink } = useLocaleContext();
  const { data, isLoading } = useChallenges();

  if (isLoading) {
    return <Loader />;
  }

  if (!data || data.length === 0) return <div>No data</div>;

  const columns: GridColDef<ChallengeItem>[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Link
          to={getLocaleLink(`/challenge/${params.row.slug}`)}
          style={{ color: '#fff', textDecoration: 'none', fontWeight: 'bold' }}
        >
          {params.value}
        </Link>
      )
    },
    {
      field: 'difficulty',
      headerName: 'Difficulty',
      width: 150,
      renderCell: (params) => {
        const difficultyValue = params.value as ChallengeItem.difficulty;
        return (
          <Chip
            variant="outlined"
            size="small"
            label={difficultyValue}
            color={difficultyColors[difficultyValue]}
            sx={{
              fontFamily: 'Silkscreen',
              fontWeight: 300,
              fontSize: '0.76rem'
            }}
          />
        );
      }
    },
    {
      field: 'completed',
      headerName: 'Completed',
      width: 150,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            position: 'relative',
            width: '26px',
            height: '26px',
            backgroundColor: params.row.completed ? 'primary.dark' : 'divider',
            borderRadius: '4px'
          }}
        >
          {params.row.completed && (
            <Star
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '16px',
                color: '#FFF'
              }}
            />
          )}
        </Box>
      )
    },
    {
      field: 'actions',
      headerName: '',
      width: 150,
      renderCell: (params) => (
        <Button
          href={getLocaleLink(`/challenge/${params.row.slug}`)}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<PlayArrow />}
        >
          Start
        </Button>
      )
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Typography variant="h4" gutterBottom>
        Challenges
      </Typography>
      <Paper sx={{ height: 700, width: '100%', overflow: 'hidden' }}>
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row: ChallengeItem) => row.slug}
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '16px'
            },
            '& .MuiDataGrid-columnHeaders': {
              color: '#fff',
              fontWeight: 'bold'
            }
          }}
        />
      </Paper>
    </Container>
  );
}
