import { useQuery } from "react-query";
import { ChallengeService } from "../client";

export const useChallenges = () => {
  const result = useQuery(
    'challenges',
    ChallengeService.getChallengesRouteApiChallengeListGet
  );

  return result;
}