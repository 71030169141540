import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import useLevels from '../components/useLevels';
import { Verified } from '@mui/icons-material';
import { useLocaleContext } from '../context/LocaleContext';

export default function Courses() {
  const { courses } = useLevels();

  const { getLocaleLink } = useLocaleContext();

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ mb: 3, mt: 1.5 }}>
        Choose Course
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {courses.map((course) => (
          <Grid
            item
            key={course.value}
            xs={12}
            sm={6}
            md={4}
            display="flex"
            justifyContent="center"
          >
            <Card
              elevation={3}
              sx={{ height: '100%', display: 'flex', flexDirection: 'column', maxWidth: 345 }}
            >
              <CardMedia
                component="img"
                sx={{ width: '70%', px: '40px', pt: '20px', mx: 'auto' }}
                image={course.logo}
                alt={course.label}
              />
              <CardContent sx={{ flexGrow: 1, pt: 1, pb: 0, px: 4 }}>
                <Typography gutterBottom variant="h6" component="h2" align="center">
                  {course.label}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 2 }}>
                  {course.description}
                </Typography>
                <List sx={{ width: '100%' }}>
                  {course.chapters.map((chapter, index) => (
                    <ListItem key={index} sx={{ pl: 0, py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <Verified fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={chapter}
                        color="text.secondary"
                        primaryTypographyProps={{ fontSize: '16px' }}
                        sx={{ m: 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <Box
                component="div"
                sx={{ display: 'flex', justifyContent: 'center', px: 2, pt: 1, pb: 3 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={getLocaleLink(`/play/${course.value}/${course.firstLevel}`)}
                >
                  Start Course
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
