import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  useTheme
} from '@mui/material';
import { useAddStudentForm } from '../../hooks/useAddStudentForm';
import { Controller, FieldError } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileService, StudentCreateForm } from '../../client';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { AxiosError } from 'axios';
import { Loader } from '../Loader';
import ProfileImageWithSelection from './ProfileImageWithSelection';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useLocaleContext } from '../../context/LocaleContext';

export const AddEditStudentUserForm = () => {
  const { studentId } = useParams();
  const { createSnackBar } = useSnackBarContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const { getLocaleLink } = useLocaleContext();

  const { data: student, isLoading: isStudentLoading } = useQuery(
    ['student-user', studentId],
    () => ProfileService.getStudentApiProfileStudentsStudentIdGet(studentId || ''),
    {
      enabled: !!studentId,
      retry: false
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
    trigger,
    setError
  } = useAddStudentForm(student);

  const addStudentMutation = useMutation(ProfileService.createStudentApiProfileStudentsPost, {
    onSuccess: () => {
      queryClient.invalidateQueries('students');
      queryClient.invalidateQueries('currentUser');
      navigate(getLocaleLink('/profile/students'));
      createSnackBar({
        content: 'New student created successfully.',
        autoHide: true,
        severity: 'success'
      });
    },
    onError: (error: AxiosError) => {
      if (error.status === 409) {
        createSnackBar({
          content: 'Username already exists.',
          autoHide: true,
          severity: 'error'
        });
      } else {
        createSnackBar({
          content: 'Adding student failed',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  });

  const editStudentMutation = useMutation(
    (updatedStudentUser: StudentCreateForm) =>
      ProfileService.updateStudentApiProfileStudentsStudentIdPut(
        studentId || '',
        updatedStudentUser
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('students');
        queryClient.invalidateQueries('currentUser');
        queryClient.invalidateQueries(['student-user', studentId]);
        navigate(getLocaleLink('/profile/students'));
        createSnackBar({
          content: 'Student details updated successfully.',
          autoHide: true,
          severity: 'success'
        });
      },
      onError: () => {
        createSnackBar({ content: 'Editing student failed', autoHide: true, severity: 'error' });
      }
    }
  );

  const triggerWithApi = async () => {
    const manualErrors = Object.entries(errors).filter(([_, value]) => value.type === 'manual');
    await trigger();
    manualErrors.forEach(([key, value]) => {
      setError(key as never, value as FieldError);
    });
  };

  const onSubmitWrapper = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValid) {
      await triggerWithApi();
    }
    if (isValid && Object.keys(errors).length === 0) {
      handleSubmit(onSubmit)(event);
    }
  };

  const onSubmit = async (data: StudentCreateForm) => {
    try {
      if (!studentId) {
        await addStudentMutation.mutateAsync(data);
        return;
      }
      await editStudentMutation.mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  if (studentId && isStudentLoading) return <Loader />;
  if (studentId && !student) return <Box component="div">Child user not found</Box>;

  return (
    <Paper sx={{ p: 5, mt: 4 }}>
      <Box component="form" onSubmit={onSubmitWrapper} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="div" display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>
              <Controller
                name="character"
                control={control}
                defaultValue={student?.character}
                render={({ field: { value, onChange } }) => (
                  <ProfileImageWithSelection
                    selectedAvatar={value ?? 'Rabbit'}
                    setSelectedAvatar={onChange}
                  />
                )}
              />

              <Stack gap={4} flex={1}>
                <Controller
                  name="username"
                  control={control}
                  defaultValue={student?.username}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={!!errors.username}
                      helperText={errors.username?.message}
                      fullWidth
                      label="Username"
                      margin="none"
                      required
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="full_name"
                  control={control}
                  defaultValue={student?.full_name}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={!!errors.full_name}
                      helperText={errors.full_name?.message}
                      fullWidth
                      label="Full Name"
                      margin="none"
                      required
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      fullWidth
                      label={!studentId ? 'Password' : 'New password (optional)'}
                      margin="none"
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      label="Confirm new password"
                      fullWidth
                      margin="none"
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verify password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" display="flex" justifyContent="flex-end" mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !!errors.confirmPassword}
              >
                {isSubmitting || addStudentMutation.isLoading || editStudentMutation.isLoading
                  ? 'Saving...'
                  : 'Save'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
